import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

declare const $: any;

export default React.forwardRef(
  (
    {
      onDatesChange,
      optional = false,
      label,
      filterDay = 29,
      requiredIndicator = "",
      errors,
      sizeClass = "form-control-lg",
      ...register
    }: any,
    ref,
  ) => {
    const rangeElement = useRef(null);

    const initialStartDate =
      filterDay === 0 ? moment() : moment().subtract(29, "days");
    const initialEndDate = moment();

    const [rangeFilter, setRangeFilter] = useState({
      start: initialStartDate,
      end: initialEndDate,
    });

    useEffect(() => {
      if (!rangeElement || !rangeElement.current) return;

      $(rangeElement.current)
        .find(".text-date")
        .html(
          rangeFilter.start.format("MMMM D, YYYY") +
            " - " +
            rangeFilter.end.format("MMMM D, YYYY"),
        );

      const cb = (start: any, end: any) => {
        setRangeFilter({ start, end });

        $(rangeElement.current)
          .find(".text-date")
          .html(
            start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY"),
          );

        if (onDatesChange) {
          onDatesChange({ start, end });
        }
      };

      $(rangeElement.current).daterangepicker(
        {
          autoUpdateInput: true,
          startDate: rangeFilter.start,
          endDate: rangeFilter.end,
          ranges: {
            Hoy: [moment(), moment()],
            Ayer: [moment().subtract(1, "days"), moment().subtract(1, "days")],
            "Últimos 7 días": [moment().subtract(6, "days"), moment()],
            "Último 30 días": [moment().subtract(29, "days"), moment()],
            "Mes actual": [moment().startOf("month"), moment().endOf("month")],
            "Mes Anterior": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
          locale: {
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            customRangeLabel: "Personalizar",
            daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
          },
        },
        cb,
      );
    }, [rangeElement]);

    return (
      <>
        {label && (
          <label className={"form-label " + requiredIndicator}>
            {label}{" "}
            {optional && (
              <span className="fs-8 text-muted ms-1">(Opcional)</span>
            )}
          </label>
        )}
        <div className={"d-flex " + (errors ? "is-invalid" : "")}>
          <div
            title="date"
            className=" form-control form-control-solid"
            ref={rangeElement}
            {...register}
          >
            <span className="text-gray-600 fw-bold text-date"></span>
            <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-2 me-0">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
              <span className="path4" />
              <span className="path5" />
              <span className="path6" />
            </i>
          </div>
        </div>
      </>
    );
  },
);
