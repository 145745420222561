import React from "react";
import InvalidFeedback from "./invalid-feedback.component";

export default React.forwardRef(
  (
    {
      label,
      value,
      counter,
      errors,
      requiredIndicator = "",
      optional = false,
      ...register
    }: any,
    ref,
  ) => {
    return (
      <>
        {label && (
          <label className={"form-label " + requiredIndicator}>
            {label}{" "}
            {optional && (
              <span className="fs-8 text-muted ms-1">(Opcional)</span>
            )}
          </label>
        )}
        <textarea
          ref={ref}
          className={
            "form-control form-control-lg form-control-solid " +
            (errors ? "is-invalid" : "")
          }
          {...register}
          rows={5}
        />

        <InvalidFeedback error={errors} />

        {counter && counter > 0 ? (
          <div className="form-text mt-1 text-end">
            {value}/{counter}
          </div>
        ) : null}
      </>
    );
  },
);
