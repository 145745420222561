import { useCallback } from "react";

const useInputValidation = () => {
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const priceRegex = /^\d+(\.\d{1,2})?$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%¡¿/&*()_+{}<>?]).{8,}$/;

  const noSpaceRegex = /^\S*$/;
  const tenDigitsRegex = /^[0-9]{10}$/;
  const numericRegex = /^[0-9]*$/;
  const alphanumericRegex = /^[0-9a-zA-Z]*$/;

  const validateEmail = (email: any) => emailRegex.test(email);
  const validatePrice = (price: any) => priceRegex.test(price);

  const handlePrice = useCallback((e: any) => {
    const key = e.key;
    const isNumber = /^[0-9]$/.test(key);
    const isControl =
      key === "Backspace" ||
      key === "Delete" ||
      key === "ArrowLeft" ||
      key === "ArrowRight" ||
      key === "Tab";
    const isDecimal = key === ".";

    if (!isNumber && !isControl && !isDecimal) {
      e.preventDefault();
    }
  }, []);

  const handleNoSpaces = useCallback((e: any) => {
    const key = e.key;
    const isSpace = key === " ";

    if (isSpace) {
      e.preventDefault();
    }
  }, []);

  const handleTenDigits = useCallback((e: any) => {
    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
    const currentValue = e.target.value.replace(/\s+/g, "");
    if (currentValue.length >= 10 && e.key !== "Backspace") {
      e.preventDefault();
    }
  }, []);

  const handleEightChars = useCallback((e: any) => {
    const maxLength = 8;
    const allowedChars = /[a-zA-Z0-9!@#$%¡¿/&*()_+{}<>?]/;

    const inputValue = e.currentTarget.value.replace(/\s+/g, "");

    if (
      inputValue.length >= maxLength &&
      e.key !== "Backspace" &&
      e.key.length === 1
    ) {
      e.preventDefault();
      return;
    }

    if (!allowedChars.test(e.key)) {
      e.preventDefault();
    }
  }, []);

  const handleSpaceReplacement = useCallback((e: any) => {
    const inputElement = e.currentTarget;
    const cursorPosition = inputElement.selectionStart || 0;

    if (e.key === " ") {
      e.preventDefault();
      const currentValue = inputElement.value;
      const newValue =
        currentValue.slice(0, cursorPosition) +
        "_" +
        currentValue.slice(cursorPosition);
      inputElement.value = newValue;
      inputElement.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
    }
  }, []);

  const handleNumericInput = useCallback((e: any) => {
    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
    const currentValue = e.target.value.replace(/\s+/g, "");
    e.target.value = currentValue;
  }, []);

  const handleAlphanumericInput = useCallback((e: any) => {
    if (!/[0-9a-zA-Z]/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
    const currentValue = e.target.value.replace(/\s+/g, "");
    e.target.value = currentValue;
  }, []);

  return {
    emailRegex,
    validatePrice,
    priceRegex,
    noSpaceRegex,
    validateEmail,
    passwordRegex,
    handlePrice,
    tenDigitsRegex,
    handleTenDigits,
    numericRegex,
    alphanumericRegex,
    handleNumericInput,
    handleSpaceReplacement,
    handleAlphanumericInput,
    handleNoSpaces,
    handleEightChars,
  };
};

export default useInputValidation;
