import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import toast from "react-hot-toast";

const ReactSwal = withReactContent(Swal);

export const AlertService = {
  warning: async (
    title: string | JSX.Element,
    content: string | JSX.Element,
    preConfirm: () => any,
    confirmButtonText = "Continuar",
  ) => {
    const { isConfirmed } = await ReactSwal.fire({
      title: title,
      html: content,
      icon: "warning",
      iconColor: "#FFC700",
      confirmButtonText: confirmButtonText,
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "btn btn-secondary order-1  me-3",
        confirmButton: "btn btn-primary order-2",
      },
      buttonsStyling: false,
      showConfirmButton: true,
      focusDeny: true,
      focusCancel: true,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: preConfirm,
    });

    return isConfirmed;
  },
  success: async (
    title: string | JSX.Element,
    content: string | JSX.Element,
    confirmButtonText = "OK",
  ) => {
    const { isConfirmed } = await ReactSwal.fire({
      title: title,
      html: content,
      icon: "success",
      iconColor: "#579743",
      confirmButtonText: confirmButtonText,
      customClass: {
        cancelButton: "btn btn-secondary order-1  me-3",
        confirmButton: "btn btn-primary order-2",
      },
      buttonsStyling: false,
    });

    return isConfirmed;
  },
  error: async (
    title: string | JSX.Element,
    content: string | JSX.Element,
    confirmButtonText = "OK",
  ) => {
    const { isConfirmed } = await ReactSwal.fire({
      title: title,
      html: content,
      icon: "error",
      iconColor: "#F1416C",
      confirmButtonText: confirmButtonText,
      customClass: {
        cancelButton: "btn btn-secondary order-1  me-3",
        confirmButton: "btn btn-primary order-2",
      },
      buttonsStyling: false,
    });

    return isConfirmed;
  },
  question: async (
    title: string | JSX.Element,
    content: string | JSX.Element,
    preConfirm: () => any,
    confirmButtonText = "Continuar",
  ) => {
    const { isConfirmed } = await ReactSwal.fire({
      title: title,
      html: content,
      icon: "question",
      iconColor: "#0070BC",
      confirmButtonText: confirmButtonText,
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "btn btn-secondary order-1  me-3",
        confirmButton: "btn btn-primary order-2",
      },
      buttonsStyling: false,
      showConfirmButton: true,
      focusDeny: true,
      focusCancel: true,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: preConfirm,
    });

    return isConfirmed;
  },
  toastSuccess: (message: string, duration = 4000) => {
    toast.success(message, {
      duration,
      iconTheme: {
        primary: "#fff",
        secondary: "#579743",
      },
      style: {
        background: "#579743",
        padding: "20px",
        fontWeight: "bold",
        color: "#fff",
      },
    });
  },
  toastError: (message: string, duration = 4000) => {
    toast.error(message, {
      duration,
      iconTheme: {
        primary: "#fff",
        secondary: "#F1416C",
      },
      style: {
        background: "#F1416C",
        padding: "20px",
        fontWeight: "bold",
        color: "#fff",
      },
    });
  },
  toastWarning: (message: string, duration = 4000) => {
    toast.error(message, {
      duration,
      icon: "⚠️",
      iconTheme: {
        primary: "#fff",
        secondary: "#F1416C",
      },
      style: {
        background: "#ffaa00",
        padding: "20px",
        fontWeight: "bold",
        color: "#fff",
      },
    });
  },
  toastLoading: (
    promise: any,
    loadingMessage: any,
    successMessage: any,
    errorMessage: any,
  ) => {
    toast.promise(
      promise,
      {
        loading: loadingMessage,
        success: successMessage,
        error: errorMessage,
      },
      {
        duration: 4000,
        style: {
          background: "#1B5451",
          padding: "20px",
          fontWeight: "bold",
          color: "#fff",
        },
      },
    );
  },
};
