import { useEffect, useMemo, useRef, useState } from "react";
import useModal from "../../hooks/close-modal.hook";
import useRequest from "../../hooks/use-request.hook";
import Button from "../../components/forms/button.component";
import Input from "../../components/forms/input.component";
import { useForm } from "react-hook-form";
import { AlertService } from "../../services/alert.service";
import { useTranslation } from "react-i18next";
import LoaderModalTable from "../../components/loader/loader-table-modal.component";
import DataTable from "../../components/datatable/datatable.component";
import style from "./blacklist.module.scss";
import { BlackListService } from "../../services/blacklist.service";

interface EditBlackListProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
  payload: any;
}

function EditBlackList({ show, onRequestClose, payload }: EditBlackListProps) {
  const { t } = useTranslation();
  const numberRegex = /^\d{10}$/;
  const modalRef = useRef<HTMLDivElement>(null);
  useModal(modalRef, show);
  const [term, setTerm] = useState<string>("");
  const { call, isRequesting } = useRequest();
  const [rows, setRows] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 20;
  const [totalRows, setTotalRows] = useState(0);

  const defaultValues = {
    blacklist: "",
    phone_number: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const columns = [
    {
      name: "Numero de teléfono",
      sortable: false,
      cell: (row: any) => {
        return <h5>{row.number}</h5>;
      },
    },
    {
      name: "Fecha de Registro",
      cell: (rows: any) => {
        const fechaHora = new Date(rows.created_at);
        const fecha = fechaHora.toISOString().split("T")[0];
        const hora = fechaHora.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
        return (
          <div className="text-nowrap">
            <h5>
              Fecha: <span className="text-muted">{fecha}</span>
            </h5>
            <h5>
              Hora: <span className="text-muted">{hora}</span>
            </h5>
          </div>
        );
      },
    },
    {
      name: "Acciones",
      sortable: false,
      center: true,
      exportable: false,
      cell: (row: any) => {
        return (
          <div className={`d-flex text-nowrap ${style.btnContainer}`}>
            <button
              type="button"
              className="btn btn-light-danger"
              onClick={() => deleteNumber(row)}
            >
              <i className="ki-duotone ki-trash fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
              </i>
              Eliminar
            </button>
          </div>
        );
      },
    },
  ];

  const closeModal = () => {
    reset(defaultValues);
    onRequestClose(false);
  };

  const deleteNumber = async (data: any) => {
    const confirmed = await AlertService.warning(
      "¡Alerta!",
      `¿Estas seguro de eliminar el numero <b>${data.number}</b>? <br> Esta acción no se puede deshacer`,
      () => Promise.resolve(),
      "Eliminar",
    );
    if (!confirmed) return;
    try {
      await BlackListService.deleteNumber(data._id, data.blacklist);
      AlertService.toastSuccess("Número eliminado correctamente");
      getFile(payload?._id);
    } catch (error) {
      console.error("Error eliminando número:", error);
      AlertService.toastError("Error eliminando número ¡Inténtalo de nuevo!");
    }
  };

  const getFile = async (id: any) => {
    const data = await call(() => BlackListService.getNumbers(String(id)));
    if (data) {
      setRows(data);
    } else {
      setRows([]);
    }
  };

  const submitNumber = async (data: any) => {
    try {
      const newData = {
        blacklist: payload._id,
        number: data.phone_number,
      };
      await BlackListService.saveNumber(newData);
      resetField("phone_number");
      AlertService.toastSuccess("Número agregado correctamente");
      getFile(payload?._id);
    } catch (error) {
      AlertService.toastError("Error agregando número");
      console.log(error);
    }
  };

  useEffect(() => {
    if (payload) {
      getFile(payload?._id);
    }
  }, [payload]);

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      ref={modalRef}
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => closeModal()}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <form onSubmit={handleSubmit(submitNumber)}>
            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
              <div className="mt-1 text-center">
                <h2>
                  Editar Blacklist
                  <a className="link-primarry fw-bold ms-2" href="#">
                    {payload?.name}
                  </a>
                </h2>
                <div className="text-muted fw-semibold fs-5">
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        'Los campos marcados con <span class="required"></span> son <a class="link-danger fw-bold ms-2" href="#">Obligatorios</a>.',
                    }}
                  ></span>
                </div>
                <div className="separator border-2 my-5"></div>
              </div>
              <div className="row mt-10">
                <div className="col-lg-5 mb-10 mb-lg-0  h-100 p-10">
                  <label className="d-flex flex-stack mb-7">
                    <span className="d-flex align-items-center me-2">
                      <span className="symbol symbol-circle symbol-50px me-6">
                        <span className="symbol-label bg-light-primary">
                          <i className="ki-duotone ki-notepad-edit fs-2x text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </span>
                      <span className="d-flex flex-column">
                        <h4 className="fw-bold">Agregar Número</h4>
                        <div className="text-muted fw-semibold fs-7">
                          Agrega un nuevo numero a la lista negra
                        </div>
                      </span>
                    </span>
                  </label>
                  <div className="d-flex flex-column flex-md-row align-items-center">
                    <div className="flex-grow-1 me-md-3 mb-3 mb-md-0">
                      <Input
                        label="Numero de teléfono"
                        requiredIndicator="required"
                        className={
                          "form-control form-control-solid" +
                          (errors.phone_number ? " is-invalid" : "")
                        }
                        placeholder={"Agregar número"}
                        {...register("phone_number", {
                          required: true,
                          pattern: {
                            value: numberRegex,
                            message: "Número de teléfono inválido",
                          },
                        })}
                      />
                    </div>
                    <Button
                      type="submit"
                      id="sendData"
                      className="btn btn-primary hover-scale mt-10 ms-2"
                      disabled={!isValid}
                      isLoading={isSubmitting}
                    >
                      Agregar
                    </Button>
                  </div>
                  {errors.phone_number && (
                    <div className="text-danger">
                      {"Número de teléfono inválido"}
                    </div>
                  )}
                </div>
                {/* TABLA BLACKLIST */}
                <div className="col-lg-7 rounded">
                  <DataTable
                    columns={columns}
                    data={rows}
                    isLoading={isRequesting}
                    height="h-500px"
                  />
                </div>
              </div>
              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button
                  type="button"
                  className="btn btn-light hover-scale"
                  onClick={() => closeModal()}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditBlackList;
