import axios from "axios";
import { STORAGE } from "../constants";
import { LocalStorageService } from "./local-storage.service";

const _HTTP_ = axios.create();

_HTTP_.interceptors.request.use((config) => {
  const token = LocalStorageService.get(STORAGE.TOKEN);
  if (token) {
    config.headers["x-token"] = token;
  }
  return config;
});

_HTTP_.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response && error.response.data) {
      const { statusCode, message } = error.response.data;
      if (error.response.status === 401) {
        handleUnauthorized();
        return Promise.reject(message || error.response.data.error);
      }

      return handleResponseCode(
        statusCode || 400,
        message || error.response.data.error,
      );
    }

    return Promise.reject(error);
  },
);

const handleUnauthorized = () => {
  const currentPath = window.location.pathname;
  const isInLoginOrHome =
    currentPath === "/" || currentPath.startsWith("/auth/login");
  LocalStorageService.clear();
  if (!isInLoginOrHome) {
    window.location.replace("/auth/login");
  }
};

const handleResponseCode = (code: number, errorMessage: any) => {
  switch (code) {
    case 400:
      if (errorMessage === "Usuario y/o contraseña no válidas") {
        errorMessage = "Tu usuario o la contraseña son incorrectos.";
      }
      return Promise.reject(errorMessage);
    case 500:
      if (errorMessage.toLowerCase() === "sesión caducada") {
        handleUnauthorized();
        return;
      }
      if (errorMessage === "Internal server error") {
        errorMessage = "Error interno del servidor.";
      }
      return Promise.reject(errorMessage || { message: "Error desconocido." });
    default:
      return Promise.reject(errorMessage || { message: "Error desconocido." });
  }
};

export const HTTP = _HTTP_;
