import useHasAccess from "../hooks/use-has-access.hook";

interface HasAccessProps {
  children: any;
  onlyRoot?: boolean;
  permission?: string;
}

function HasAccess({ children, onlyRoot, permission }: HasAccessProps) {
  const { isAllowed } = useHasAccess({ onlyRoot, permission });

  return isAllowed ? children : null;
}
export default HasAccess;
