import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useModal from "../../hooks/close-modal.hook";
import { set, useForm } from "react-hook-form";
import Button from "../../components/forms/button.component";
import Input from "../../components/forms/input.component";
import { AlertService } from "../../services/alert.service";
import { usePapaParse } from "react-papaparse";
import { Dropzone } from "@dropzone-ui/react";
import { BlackListService } from "../../services/blacklist.service";
interface AddBlackListProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
}

function AddBlackList({ show, onRequestClose }: AddBlackListProps) {
  const modalRef = useRef<HTMLDivElement>(null);
  useModal(modalRef, show);
  const regex = /^\d{10}$/;
  const { readString } = usePapaParse();
  const [files, setFiles] = useState<any[]>([]);
  const defaultValues = {
    name: "",
    csv: "",
    phone_numbers: 0,
  };
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const removeFile = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    setFiles([]);
    setValue("csv", "");
  };

  const handleCsvChange = (files: any[]) => {
    const file = files[0];
    if (file && file.file.type === "text/csv") {
      processCsvFile(file.file);
    } else {
      AlertService.toastError("El archivo debe ser de tipo CSV");
      setValue("csv", "");
    }
  };

  const processCsvFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const csvData = event.target?.result as string;
      readString(csvData, {
        header: false,
        skipEmptyLines: true,
        complete: (result: any) => {
          const phoneNumbers = result.data
            .map((row: any) => row[0]?.replace(/\D/g, ""))
            .filter((phoneNumber: string) => regex.test(phoneNumber));
          if (phoneNumbers.length === 0) {
            AlertService.toastError(
              "No se encontraron números válidos en el archivo",
            );
            setFiles([]);
            setValue("phone_numbers", 0);
          } else {
            const csvContent = phoneNumbers
              .map((num: string) => num)
              .join("\n");
            const blob = new Blob([csvContent], {
              type: "text/csv;charset=utf-8;",
            });
            const updatedFile = new File([blob], "filtered_numbers.csv", {
              type: "text/csv",
            });
            setFiles([updatedFile]);
            setValue("csv", updatedFile as any);
            setValue("phone_numbers", phoneNumbers.length);
          }
        },
      });
    };
    reader.readAsText(file);
  };

  const submit = async (data: any) => {
    const confirmed = await AlertService.question(
      "Subir Blacklist",
      `¿Estás seguro de agregar la lista negra? <b><br/> Se detectaron ${watch("phone_numbers")} números de teléfono</b>`,
      () => Promise.resolve(),
      "Aceptar",
    );
    if (!confirmed) return;
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("csv", data.csv);
      await BlackListService.saveBlackList(formData);
      setFiles([]);
      reset(defaultValues);
      AlertService.toastSuccess("Lista negra agregada correctamente");
      onRequestClose(true);
    } catch (error) {
      AlertService.toastError("Ocurrió un error al agregar la lista negra");
      console.log(error);
    }
  };
  const closeModal = () => {
    onRequestClose(false);
    setFiles([]);
    reset(defaultValues);
  };
  return (
    <div
      aria-hidden="true"
      className="modal fade"
      ref={modalRef}
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => closeModal()}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <form onSubmit={handleSubmit(submit)}>
            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
              <div className="mt-1 text-center">
                <h2>Agregar Blacklist</h2>
                <div className="text-muted fw-semibold fs-5">
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        'Los campos marcados con <span class="required"></span> son <a class="link-danger fw-bold ms-2" href="#">Obligatorios</a>.',
                    }}
                  ></span>
                </div>
                <div className="separator border-2 my-5"></div>
              </div>
              <div className="row mt-10">
                <div className="col-lg-8 mb-10 mb-lg-0 h-100 p-10">
                  <label className="d-flex flex-stack mb-7">
                    <span className="d-flex align-items-center me-2">
                      <span className="symbol symbol-circle symbol-50px me-6">
                        <span className="symbol-label bg-light-primary">
                          <i className="ki-duotone ki-notepad-edit fs-2x text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </span>
                      <span className="d-flex flex-column">
                        <h4 className="fw-bold">
                          Datos generales de la Blacklist
                        </h4>
                        <div className="text-muted fw-semibold fs-7">
                          Agrega los datos generales de la lista negra
                        </div>
                      </span>
                    </span>
                  </label>
                  <div>
                    <div className="fv-row mb-7">
                      <Input
                        label="Nombre"
                        requiredIndicator="required"
                        placeholder={"Ingresa el nombre de la lista negra"}
                        errors={errors.name}
                        {...register("name", {
                          required: true,
                          setValueAs: (value) => value.trim(),
                        })}
                      />
                    </div>
                  </div>
                  <div className="fv-row mb-7">
                    <div className="">
                      {files.length === 0 ? (
                        <Dropzone
                          onChange={handleCsvChange}
                          value={files}
                          className="dropzone cursor-default"
                          accept=".csv"
                          maxFiles={1}
                        >
                          <div className="dz-mkssage">
                            <>
                              <i className="ki-duotone ki-file-up fs-5x text-primary me-4">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                              <div className="d-flex flex-center flex-grow-1 flex-wrap flex-md-nowrap">
                                <div className="mb-3 mb-md-0 fw-semibold">
                                  <h5 className="text-gray-900 fw-bold">
                                    Arrastra o haz click aquí para subir tu
                                    archivo csv
                                  </h5>
                                  <div className="fs-7 pe-7 text-info">
                                    Archivo permitido .csv
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                        </Dropzone>
                      ) : (
                        <div className="dropzone cursor-default">
                          <div className="dz-g">
                            <i className="ki-duotone ki-file-added text-primary fs-5x text-primary me-4">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                            <div className="d-flex flex-column align-items-center flex-grow-1">
                              <div className="mb-3 mb-md-0 fw-semibold text-center">
                                <h5 className="text-gray-900 fw-bold">
                                  Archivo cargado correctamente
                                </h5>
                                <div className="fs-7 text-info">
                                  {files[0]?.name}
                                </div>
                              </div>
                              <a
                                className="btn btn-danger px-6 text-nowrap btn-sm mt-3"
                                href="#"
                                onClick={removeFile}
                              >
                                Eliminar
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 bg-light rounded d-flex flex-column justify-content-center align-items-center text-center">
                  <span className="symbol symbol-circle symbol-50px mb-6">
                    <span className="symbol-label bg-light-warning">
                      <i className="ki-duotone ki-information-3 fs-2x text-warning">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                    </span>
                  </span>
                  <h4 className="fw-bold mb-4">¡Atención!</h4>
                  <p className="text-muted fw-semibold text-justify">
                    Debes agregar un archivo de una columna, sin encabezados y
                    en formato CSV
                    <br />
                    Ejemplo:
                  </p>
                  <img
                    src="/assets/media/examples/blacklist_example.png"
                    className="img-fluid"
                    width={"150px"}
                  />
                </div>
              </div>
              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button
                  type="button"
                  className="btn btn-light hover-scale"
                  onClick={() => closeModal()}
                >
                  Cerrar
                </button>
                <Button
                  type="submit"
                  id="sendData"
                  className="btn btn-primary ms-3 hover-scale"
                  disabled={!isValid || files.length === 0}
                  isLoading={isSubmitting}
                >
                  Agregar
                  <i className="ki-duotone ki-send ms-2 fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddBlackList;
