import { API_ENDPOINT } from "../constants";
import { HTTP } from "./http.service";

export const BlackListService = {
  getBlackLists: () => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}blacklist`);
  },
  saveBlackList: (data: any) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}blacklist`, data);
  },
  getNumbers: (id: string) => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}blacklist/numbers/${id}`);
  },
  saveNumber: (data: any) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}blacklist/number`, data);
  },
  deleteBlackList: (id: string) => {
    return HTTP.delete<any, any>(`${API_ENDPOINT.CORE}blacklist`, {
      data: { id: id },
    });
  },
  changeStatus: (id: string, status: boolean) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}blacklist/set-status`, {
      blacklist: id,
      status: status,
    });
  },
  deleteNumber: (id: string, blacklist: string) => {
    return HTTP.delete<any, any>(`${API_ENDPOINT.CORE}blacklist/number`, {
      data: { id: id, blacklist: blacklist },
    });
  },
};
