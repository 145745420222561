import { API_ENDPOINT } from "../constants";
import { HTTP } from "./http.service";
import { STORAGE } from "../constants";
import { LocalStorageService } from "../services/local-storage.service";

const token = LocalStorageService.get(STORAGE.TOKEN);

export const CampaignsService = {
  getBlacklist: () => {
    return HTTP.get<any, any>(
      `${API_ENDPOINT.CORE}blacklist/667ef8cd815d21ebb52aa34f`,
    );
  },
  getLisCampaigns: () => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}campaigns`);
  },
  changeStatus: (id: string, status: boolean) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}campaigns/status`, {
      campaign: id,
      status: status,
    });
  },
  downloadFile: (id: string, type: string) => {
    return HTTP.post<any, any>(
      `${API_ENDPOINT.CORE}campaigns/download/${type}/${id}`,
    );
  },
  uploadCampaign: (data: any) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}campaigns`, data);
  },
  getDialers: () => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}campaigns/ucontact/dialers`);
  },
  getRegisters: (campaignId: string) => {
    return HTTP.get<any, any>(
      `${API_ENDPOINT.CORE}campaigns/registers/${campaignId}`,
      {
        headers: {
          "x-token": token,
        },
      },
    );
  },
  uploadBase: (data: any, type: string) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}campaigns/${type}`, data);
  },
};
