import { useEffect, useState } from "react";
import { PROFILES } from "../../constants";
import { UsersService } from "../../services/users.service";
import useBgPages from "../../hooks/bg-pages.hook";
import useRequest from "../../hooks/use-request.hook";
import Aside from "../../components/layout/asaid.component";
import Header from "../../components/layout/header.component";
import Footer from "../../components/layout/footer.component";
import Datatable from "../../components/datatable/datatable.component";
import AddUser from "./add-user.modal";

interface User {
  _id: string;
  username: string;
  full_name: string;
  email: string;
  status: boolean;
  profile: string;
  role: string;
  updated_at: string;
  created_at: string;
  ucontact: string;
}

function UsersPage() {
  useBgPages();
  const { call, isRequesting } = useRequest();
  const [rows, setRows] = useState<User[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  const [showModal, setShowModal] = useState({
    show: false,
    data: null,
  });

  const columns = [
    {
      name: "Datos del usuario",
      sortable: true,
      cell: (row: any) => {
        return (
          <div className="text-nowrap">
            <p className="mb-0">
              <strong>Nombre: </strong>
              <span className="text-muted fs-4 ms-1 fw-bolder">{row.name}</span>
            </p>
            <div className="d-flex align-items-center">
              <p className="mb-0">
                <strong>Usuario: </strong>
                <span className="text-muted fs-4 ms-1 fw-bolder">
                  {row.username}
                </span>
              </p>
            </div>
          </div>
        );
      },
    },
    {
      name: "Perfil",
      sortable: false,
      center: true,
      cell: (row: any) => {
        return (
          <div className="text-nowrap">
            {(() => {
              switch (row.profile.name) {
                case PROFILES.SUPERVISOR:
                  return (
                    <span className="badge badge-light-primary fs-6">
                      <i className="ki-duotone ki-user-square text-primary fs-3 me-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                      Supervisor
                    </span>
                  );
                case PROFILES.REPORTS:
                  return (
                    <span className="badge badge-light-primary fs-6">
                      <i className="ki-duotone ki-tablet-book text-primary fs-3 me-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      Reportería
                    </span>
                  );
                case PROFILES.AGENTE:
                  return (
                    <span className="badge badge-light-primary fs-6">
                      <i className="ki-duotone ki-user-tick text-primary fs-3 me-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                      Agente
                    </span>
                  );
                default:
                  return (
                    <span className="badge badge-light-primary fs-6">
                      <i className="ki-duotone ki-bookmark text-primary fs-3 me-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      {row.profile.name}
                    </span>
                  );
              }
            })()}
          </div>
        );
      },
    },
    {
      name: "uContact ID",
      sortable: false,
      center: true,
      cell: (row: any) => {
        return row.profile.name === PROFILES.AGENTE ? (
          <span className="text-info fs-5 fw-bolder">{row.id_ucontact}</span>
        ) : (
          <span className="text-muted fw-bolder fs-4 pe-2">
            <i className="ki-duotone ki-abstract-45 fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>{" "}
            No aplica
          </span>
        );
      },
    },
    {
      name: "Acciones",
      sortable: false,
      center: true,
      exportable: false,
      cell: (rows: any) => {
        return (
          <>
            <div className="text-nowrap">
              <a
                className="btn btn-light-primary btn-sm btn-flex btn-center"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                type="button"
              >
                <span className="fw-bolder">Acciones</span>
                <i className="ki-duotone ki-down fs-5 ms-1" />
              </a>
              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-150px py-3"
                data-kt-menu="true"
              >
                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    href="#"
                    onClick={() => setShowModal({ show: true, data: rows })}
                  >
                    <i className="ki-duotone ki-notepad-edit fs-2 text-primary">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="ms-2">Editar</span>
                  </a>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const getUsers = async () => {
    const response = await call(() => UsersService.getUsers());
    if (response && Array.isArray(response)) {
      setTotalUsers(response.length);
      setRows(response);
    }
  };

  const handleShowModal = async (refresh: boolean) => {
    if (refresh) {
      await getUsers();
    }
    setShowModal({ show: false, data: null });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Aside />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <Header />
          <div
            className="content fs-6 d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">Usuarios</li>
                    <li className="breadcrumb-item text-muted"></li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-profile-user fs-3x">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                      </i>
                      <span className="ms-3">Usuarios</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                {" "}
                <div className="row gy-5 g-xl-10">
                  <div className="col-xl-4 mb-xl-10">
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">
                            Estadisticas
                          </span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            Usuarios
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <span className="btn btn-clean btn-sm btn-icon btn-icon-primary me-n3">
                            <i className="ki-duotone ki-category fs-1 text-primary">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                          </span>
                        </div>
                      </div>
                      <div className="card-body pt-1">
                        <div className="d-flex flex-stack mb-2 item-border-hover px-3 py-2 ms-n3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-profile-user fs-3x text-info w-35px me-6 hover-scale">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                              <span className="path4"></span>
                            </i>
                            <div className="pe-2">
                              <a
                                title="s"
                                className="fs-5 text-gray-800 text-hover-info fw-bolder"
                                href="#"
                              >
                                Mis Usuarios
                              </a>
                              <div className="text-gray-400 fw-semibold mt-1">
                                Total activos
                              </div>
                            </div>
                          </div>
                          <span className="label rounded bg-light w-25px h-25px fs-5 fw-bolder ">
                            {totalUsers}
                          </span>
                        </div>
                        <div className="d-flex align-items-center flex-nowrap text-nowrap py-1">
                          <a
                            href="#"
                            className="btn btn-primary hover-scale"
                            onClick={() =>
                              setShowModal({ show: true, data: null })
                            }
                          >
                            <i className="ki-duotone ki-abstract-10 fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            Agregar usuario
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 mb-5 mb-xl-10">
                    <div className="row g-5 g-xl-10 h-xxl-50 mb-0 mb-xl-10">
                      <div className="card mb-5 mb-xxl-8">
                        <div className="card-header align-items-center border-0 mt-5">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="fw-bolder fw-bold fs-2">
                              Usuarios
                            </span>
                            <span className="text-gray-400 mt-2 fw-semibold fs-6">
                              Registrados
                            </span>
                          </h3>
                        </div>
                        <div className="card-body pt-1">
                          <Datatable
                            columns={columns}
                            data={rows}
                            isLoading={isRequesting}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <AddUser
        show={showModal.show}
        onRequestClose={(refresh: any) => handleShowModal(refresh)}
        payload={showModal.data}
      />
    </>
  );
}
export default UsersPage;
