import React from "react";
import InvalidFeedback from "./invalid-feedback.component";

export default React.forwardRef(
  (
    {
      type = "text",
      optional = false,
      label,
      placeholder,
      validatePassword,
      requiredIndicator = "",
      errors,
      currentLength,
      maxLength,
      children,
      sizeClass = "form-control-lg",
      ...register
    }: any,
    ref,
  ) => {
    return (
      <>
        <div
          data-kt-password-meter={type === "password" ? "true" : "undefined"}
        >
          <div className="mb-1">
            {label && (
              <label className={"form-label " + requiredIndicator}>
                {label}{" "}
                {optional && (
                  <span className="fs-8 text-muted ms-1">(Opcional)</span>
                )}
              </label>
            )}
            <div className={"d-flex " + (errors ? "is-invalid" : "")}>
              <input
                type={type}
                ref={ref}
                placeholder={placeholder}
                autoComplete={type === "password" ? "new-password" : "off"}
                className={"form-control form-control-solid " + sizeClass}
                {...register}
              />
              {children}

              {type === "password" && (
                <div className="position-relative mb-0">
                  <span
                    className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                    data-kt-password-meter-control="visibility"
                  >
                    <i className="ki-duotone ki-eye-slash fs-1">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                    </i>
                    <i className="ki-duotone ki-eye d-none fs-1">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                    </i>
                  </span>
                </div>
              )}
            </div>

            <InvalidFeedback error={errors} validatePassword />

            {maxLength && maxLength > 0 && (
              <div className="form-text mt-1 text-end">
                {currentLength}/{maxLength}
              </div>
            )}
          </div>
        </div>
      </>
    );
  },
);
