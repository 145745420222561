import { useEffect, useState } from "react";
import { ReportsServices } from "../../services/reports.service";
import { AlertService } from "../../services/alert.service";
import useBgPages from "../../hooks/bg-pages.hook";
import useRequest from "../../hooks/use-request.hook";
import Aside from "../../components/layout/asaid.component";
import Header from "../../components/layout/header.component";
import Footer from "../../components/layout/footer.component";
import Datatable from "../../components/datatable/datatable.component";
import Select from "react-select";
import DatePicker from "../../components/forms/date-picker.component";
import moment, { Moment } from "moment";

interface File {
  original_name: string;
  key_s3: string;
  _id: string;
}

interface Typification {
  label: string;
}

interface Campaigns {
  _id: string;
  name: string;
  file: File;
  blacklists: string[];
  typifications: Typification[];
  product: string;
  dialer: string;
  status: string;
  total_blocked_blacklist: number;
  total_blocked_pnn: number;
  total_registers: number;
  updated_at: string;
  created_at: string;
}

interface StatisticsProps {
  iconClass: string;
  count: number;
  label: string;
  iconColor: string;
}

interface CSVData {
  [key: string]: string;
}

function ListCampaignsPage() {
  useBgPages();
  const { call, isRequesting } = useRequest();
  const [rows, setRows] = useState<Campaigns[]>([]);
  const [totalRegisters, settotalRegisters] = useState<number>(0);
  const [totalSale, settotalSale] = useState<number>(0);
  const [totalRespool, settotalRespool] = useState<number>(0);
  const [totalReschedule, settotalReschedule] = useState<number>(0);
  const [totalNoAction, settotalNoAction] = useState<number>(0);
  const [campaigns, setCampaigns] = useState<any>([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedCampaignName, setSelectedCampaignName] = useState("");
  const [filterRangeDates, setFilterRangeDates] = useState({
    start: moment(),
    end: moment(),
  });

  const columns = [
    {
      name: "Fecha",
      sortable: true,
      width: 30,
      cell: (rows: any) => {
        const fechaHora = moment(rows.created_at);
        const fecha = fechaHora.format("DD-MM-YYYY");
        const hora = fechaHora.format("HH:mm:ss");

        return (
          <div className="text-nowrap">
            <h5>
              Fecha: <span className="text-muted">{fecha}</span>
            </h5>
            <h5>
              Hora: <span className="text-muted">{hora}</span>
            </h5>
          </div>
        );
      },
    },
    {
      name: "Agente",
      sortable: true,
      width: 20,
      cell: (row: any) => {
        return (
          <div className="text-nowrap">
            <p className="mb-0">
              <span className="fs-4 ms-1 fw-bolder">
                {row.agent?.name ? (
                  row.agent.name
                ) : (
                  <span className="text-muted fw-bolder fs-4 pe-2">
                    <i className="ki-duotone ki-abstract-45 fs-2">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>{" "}
                    No aplica
                  </span>
                )}
              </span>
            </p>
          </div>
        );
      },
    },
    {
      name: "Nivel 1",
      sortable: true,
      width: 10,
      cell: (row: any) => {
        return (
          <div className="w-150px">
            <p className="mb-0">
              <span className="fs-4 ms-1 fw-bolder d-inline-block text-break">
                {row.level_1 ? (
                  row.level_1
                ) : (
                  <span className="text-muted fw-bolder fs-4 pe-2">
                    <i className="ki-duotone ki-abstract-45 fs-2">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>{" "}
                    No aplica
                  </span>
                )}
              </span>
            </p>
          </div>
        );
      },
    },
    {
      name: "Nivel 2",
      sortable: true,
      width: 10,
      cell: (row: any) => {
        return (
          <div className="w-150px">
            <p className="mb-0">
              <span className="fs-4 ms-1 fw-bolder d-inline-block text-break">
                {row.level_2 ? (
                  row.level_2
                ) : (
                  <span className="text-muted fw-bolder fs-4 pe-2">
                    <i className="ki-duotone ki-abstract-45 fs-2">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>{" "}
                    No aplica
                  </span>
                )}
              </span>
            </p>
          </div>
        );
      },
    },
    {
      name: "Nivel 3",
      sortable: true,
      width: 10,
      cell: (row: any) => {
        return (
          <div className="w-150px">
            <p className="mb-0">
              <span className="fs-4 ms-1 fw-bolder d-inline-block text-break">
                {row.level_3 ? (
                  row.level_3
                ) : (
                  <span className="text-muted fw-bolder fs-4 pe-2">
                    <i className="ki-duotone ki-abstract-45 fs-2">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>{" "}
                    No aplica
                  </span>
                )}
              </span>
            </p>
          </div>
        );
      },
    },
    {
      name: "Acción",
      sortable: true,
      width: 30,
      cell: (row: any) => {
        const iconMapping: Record<
          string,
          { iconClass: string; colorClass: string; text: string }
        > = {
          RESPOOL: {
            iconClass: "ki-call",
            colorClass: "text-dark",
            text: "Llamar inmediato",
          },
          RESCHEDULE: {
            iconClass: "ki-calendar-add",
            colorClass: "text-info",
            text: "Reagendar",
          },
          SALE: {
            iconClass: "ki-two-credit-cart",
            colorClass: "text-primary",
            text: "Venta",
          },
          NO_ACTION: {
            iconClass: "ki-minus-circle",
            colorClass: "text-danger",
            text: "Sin acción",
          },
        };
        const { iconClass, colorClass, text } = iconMapping[row.action] || {
          text: "Sin información",
        };

        const fechaHora = moment(row.time_to_reschedule);
        const fecha = fechaHora.format("DD-MM-YYYY");
        const hora = fechaHora.format("HH:mm:ss");

        return (
          <div className="text-nowrap">
            <p className="mb-3">
              {iconClass && (
                <i className={`ki-duotone ${iconClass} fs-2x ${colorClass}`}>
                  {[...Array(8)].map((_, idx) => (
                    <span key={idx} className={`path${idx + 1}`}></span>
                  ))}
                </i>
              )}
              <span className="fs-4 ms-2 fw-bolder">{text}</span>
            </p>
            {row.action === "RESCHEDULE" && (
              <>
                <h5 className="mb-0">
                  Fecha: <span className="text-muted">{fecha}</span>
                </h5>
                <h5 className="mb-0">
                  Hora: <span className="text-muted">{hora}</span>
                </h5>
              </>
            )}
          </div>
        );
      },
    },
    {
      name: "Comentarios",
      sortable: true,
      center: false,
      width: 300,
      cell: (row: any) => {
        return (
          <span className="fs-4 ms-1 fw-bolder w-350px">
            {row.comments ? (
              row.comments
            ) : (
              <span className="text-muted fw-bolder fs-4 pe-2">
                <i className="ki-duotone ki-abstract-45 fs-2">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>{" "}
                No aplica
              </span>
            )}
          </span>
        );
      },
    },
  ];

  const getRegisters = async (id: string) => {
    if (!filterRangeDates.start || !filterRangeDates.end) return;
    const init_date = filterRangeDates.start.format("YYYY-MM-DD");
    const end_date = filterRangeDates.end.format("YYYY-MM-DD");
    try {
      const response = await call(() =>
        ReportsServices.getRegisters(
          init_date,
          end_date,
          selectedCampaign || id,
        ),
      );
      if (response && Array.isArray(response)) {
        let sale = 0;
        let respool = 0;
        let reschedule = 0;
        let noAction = 0;

        response.forEach((campaign) => {
          switch (campaign.action) {
            case "SALE":
              sale++;
              break;
            case "RESPOOL":
              respool++;
              break;
            case "RESCHEDULE":
              reschedule++;
              break;
            case "NO_ACTION":
              noAction++;
              break;
            default:
              break;
          }
        });

        settotalSale(sale);
        settotalRespool(respool);
        settotalReschedule(reschedule);
        settotalNoAction(noAction);
        settotalRegisters(response.length);
        setRows(response);
        AlertService.toastSuccess(
          "¡ Éxito !, Información actualizada correctamente.",
        );
      }
    } catch (error) {
      console.error("Error al obtener las campañas:", error);
      AlertService.toastError(
        "¡ Atención !, Error al obtener la información de las campañas, contacta a tu administrador.",
      );
    }
  };

  const getCampaigns = async () => {
    const data = await ReportsServices.getCampaigns();
    if (data) {
      const filteredData = data.filter((item: any) => item.status === "ACTIVE");
      const mappedCampaigns = filteredData.map((item: any) => ({
        value: item._id,
        label: item.name,
      }));
      setCampaigns(mappedCampaigns);
      if (mappedCampaigns.length > 0) {
        setSelectedCampaign(mappedCampaigns[0].value);
        setSelectedCampaignName(mappedCampaigns[0].label);
        getRegisters(mappedCampaigns[0].value);
      }
    }
  };

  const Statistics: React.FC<StatisticsProps> = ({
    iconClass,
    count,
    label,
    iconColor,
  }) => {
    return (
      <div className="alert alert-dismissible border-dashed border-gray-500 rounded min-w-150px py-3 px-4 me-6 mb-3">
        <div className="d-flex align-items-center">
          <i className={`${iconClass} fs-2x hover-scale me-2 ${iconColor}`}>
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
            <span className="path5"></span>
            <span className="path6"></span>
            <span className="path7"></span>
            <span className="path8"></span>
          </i>
          <div className="fs-2 fw-bold">{count.toLocaleString()}</div>
        </div>
        <div className="fw-bolder fs-4 text-muted">{label}</div>
      </div>
    );
  };

  const searchData = async () => {
    getRegisters(selectedCampaign);
  };

  const downloadRegisters = async (name: string) => {
    const promise = async () => {
      if (!filterRangeDates.start || !filterRangeDates.end) return;

      const init_date = filterRangeDates.start.format("YYYY-MM-DD");
      const end_date = filterRangeDates.end.format("YYYY-MM-DD");

      try {
        const response = await ReportsServices.downloadFile(
          init_date,
          end_date,
          selectedCampaign,
        );

        if (typeof response === "string" && response.trim()) {
          const dataArray = parseCSV(response);

          const csv = generateCSV(dataArray);
          downloadFile(
            csv,
            `Registros de llamadas de la campaña ${name} del ${init_date} al ${end_date}.csv`,
          );
        } else {
          console.error("La respuesta no es una cadena válida o está vacía.");
          AlertService.toastError(
            "¡ Atención !, No pudimos descargar el archivo, contacta al administrador.",
          );
        }
      } catch (error) {
        AlertService.toastError(
          "¡ Atención !, No pudimos descargar el archivo, contacta al administrador.",
        );
        console.error("Error al descargar los registros:", error);
      }
    };

    const parseCSV = (csvString: string): CSVData[] => {
      const lines = csvString
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => line);

      const headers = lines[0]
        .split(",")
        .map((header) => header.replace(/"/g, "").trim());

      const data: CSVData[] = lines.slice(1).map((line) => {
        const values = line
          .split(",")
          .map((value) => value.replace(/"/g, "").trim());
        return headers.reduce((obj: CSVData, header, index) => {
          obj[header] = values[index] || "";
          return obj;
        }, {});
      });

      return data;
    };

    const generateCSV = (data: CSVData[]) => {
      if (data.length === 0) return "";
      const headers = Object.keys(data[0]);
      const rows = data.map((item) =>
        headers.map((header) => item[header]).join(","),
      );

      return [headers.join(","), ...rows].join("\n");
    };

    const downloadFile = (data: string, filename: string) => {
      const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    };

    AlertService.toastLoading(
      promise(),
      "Descargando archivo, por favor espera..",
      "¡ Éxito !, Archivo descargado correctamente.",
      "¡ Atención !, No pudimos descargar el archivo, contacta al administrador.",
    );
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Aside />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <Header />
          <div
            className="content fs-7 d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">Reportes</li>
                    <li className="breadcrumb-item text-muted"></li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-graph-up fs-3x">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                        <span className="path6"></span>
                      </i>
                      <span className="ms-3">Campañas</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-7 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="card mb-5 mb-xl-10">
                  <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                      <div className="me-7 mb-4">
                        <i className="ki-duotone ki-setting-3 fs-4x hover-scale me-2 mt-5">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                          <span className="path4"></span>
                          <span className="path5"></span>
                        </i>
                      </div>
                      <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-8">
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-2">
                              <span className="text-gray-900 fs-2 fw-bold me-1">
                                Estadísticas y filtros.
                              </span>
                            </div>
                            <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                              <span className="badge badge-light-info fw-bolder ms-0 fs-8 py-2 px-3">
                                Selecciona fecha y una campaña, para actualizar
                                la información.
                              </span>
                            </div>
                          </div>
                          <div className="d-flex my-1">
                            <DatePicker
                              onDatesChange={setFilterRangeDates}
                              filterDay={0}
                            />
                            <Select
                              options={campaigns}
                              noOptionsMessage={() => (
                                <div className="empty-select">
                                  {" "}
                                  No hay opciones disponibles
                                </div>
                              )}
                              className="form-select-custom ms-3"
                              classNamePrefix="form-select-custom"
                              placeholder="Selecciona una campaña"
                              onChange={(
                                selectedOption: {
                                  value: string;
                                  label: string;
                                } | null,
                              ) => {
                                if (selectedOption) {
                                  setSelectedCampaign(selectedOption.value);
                                  setSelectedCampaignName(selectedOption.label);
                                }
                              }}
                              value={campaigns.find(
                                (campaign: any) =>
                                  campaign.value === selectedCampaign,
                              )}
                            />
                            <a
                              className="btn btn-light-primary ms-3 fw-bolder"
                              onClick={() => {
                                searchData();
                              }}
                            >
                              <i className="ki-duotone ki-search-list fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                              </i>
                              Buscar{" "}
                            </a>
                          </div>
                        </div>

                        <div className="d-flex flex-wrap flex-stack">
                          <div className="d-flex flex-column flex-grow-1 pe-8">
                            <div className="d-flex flex-wrap">
                              <Statistics
                                iconClass="ki-duotone ki-two-credit-cart"
                                count={totalSale}
                                label="Ventas"
                                iconColor="text-primary"
                              />
                              <Statistics
                                iconClass="ki-duotone ki-calendar-add"
                                count={totalReschedule}
                                label="Reagendar"
                                iconColor="text-info"
                              />
                              <Statistics
                                iconClass="ki-duotone ki-call"
                                count={totalRespool}
                                label="Llamadas"
                                iconColor="text-dark"
                              />
                              <Statistics
                                iconClass="ki-duotone ki-minus-circle"
                                count={totalNoAction}
                                label="Sin acción"
                                iconColor="text-danger"
                              />
                            </div>
                          </div>
                          <div className="d-flex align-items-end  flex-column mt-3">
                            <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                              <span className="d-flex align-items-center text-dark fw-bolder text-hover-primary me-5 mb-2 fs-5">
                                Total de regitsros:
                              </span>
                              <span className="d-flex align-items-center text-primary fs-3 fw-bolder text-hover-primary mb-3">
                                {totalRegisters.toLocaleString()}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-bold">
                      <li className="nav-item"></li>
                      <li className="nav-item"></li>
                    </ul>
                  </div>
                </div>
                <div className="row g-xl-12">
                  <div className="col-xl-12 mb-5">
                    <div className="card">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">
                            Registros
                          </span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-7">
                            Listado
                          </span>
                        </h3>
                        {totalRegisters !== 0 && (
                          <div className="card-toolbar">
                            <button
                              type="button"
                              className="btn btn-light-info fw-bolder me-2"
                              onClick={() => {
                                downloadRegisters(selectedCampaignName);
                              }}
                            >
                              <i className="ki-duotone ki-file-down fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                              Descargar
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="card-body pt-1">
                        <Datatable
                          columns={columns}
                          data={rows}
                          isLoading={isRequesting}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
export default ListCampaignsPage;
