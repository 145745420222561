import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useHasAccess from "../hooks/use-has-access.hook";

interface HasAccessRouteProps {
  onlyRoot?: boolean;
  permission?: string;
}

function HasAccessRoute({ onlyRoot, permission }: HasAccessRouteProps) {
  const navigate = useNavigate();
  const { isAllowed } = useHasAccess({ onlyRoot, permission });

  useEffect(() => {
    if (isAllowed === false) {
      navigate("/404");
    }
  }, [isAllowed, navigate]);

  if (isAllowed === null) {
    return null;
  }

  return <Outlet />;
}

export default HasAccessRoute;
