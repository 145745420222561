import style from "./loader.module.scss";

function Loader() {
  return (
    <div className="h-500px">
      <div className={`${style.pulse}`}></div>
      <h3 className={style.textloader}>Procesando...</h3>
    </div>
  );
}

export default Loader;
