import { API_ENDPOINT } from "../constants";
import { HTTP } from "../services/http.service";

export const UsersService = {
  getUsers: () => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}users`);
  },
  saveUser: (data: any) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}users/`, data);
  },
  editUser: (data: any, _id: string) => {
    return HTTP.put<any, any>(`${API_ENDPOINT.CORE}users/${_id}`, data);
  },
};
