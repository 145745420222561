import { useEffect, useRef, useState } from "react";
import { Dropzone, ExtFile } from "@dropzone-ui/react";
import { useForm, Controller } from "react-hook-form";
import { AlertService } from "../../services/alert.service";
import { CampaignsService } from "../../services/campaings.service";
import Button from "../../components/forms/button.component";
import useModal from "../../hooks/close-modal.hook";
import Select from "react-select";
import InvalidFeedback from "../../components/forms/invalid-feedback.component";

interface BaseModalProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
  payload: any;
}

function BaseModal({ show, onRequestClose, payload }: BaseModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);
  const [dialerOptions, setDialerOptions] = useState<any>([]);
  useModal(modalRef, show);

  const defaultValues = {
    _id: "",
    name: "",
    file: "",
    type: "",
    dailer: "",
  };

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const file = watch("file");

  const updateFiles = (incomingFiles: ExtFile[]) => {
    const allowedExtensions = [".txt", ".csv"];
    const file = incomingFiles[0]?.file as File | null;

    if (file && allowedExtensions.includes(`.${file.name.split(".").pop()}`)) {
      setValue("file", file as any);
    } else {
      AlertService.toastError(
        `Solo se permiten archivos con extensión ${payload?.type === "filtered" ? ".csv" : ".txt"}`,
      );

      setValue("file", "");
    }
  };

  const removeFile = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    setValue("file", "");
  };

  const resetForm = () => {
    onRequestClose(false);
    reset(defaultValues);
  };

  const closeModal = () => {
    onRequestClose(false);
    reset(defaultValues);
  };

  const getDialers = async () => {
    const data = await CampaignsService.getDialers();
    if (data) {
      const mappedDialers = data.map((item: any) => ({
        value: item.campaign,
        label: item.campaign,
      }));
      setDialerOptions(mappedDialers);
    } else {
      setDialerOptions([]);
    }
  };

  const submit = async (data: any) => {
    if (!file) {
      AlertService.toastError(
        "¡ Atención !, necesitas cargar una base en formato TXT.",
      );
      return;
    }
    const formData = new FormData();
    formData.append("campaign", data._id);
    formData.append("file", file);
    if (data.dailer) {
      formData.append("dailer", data.dailer);
    }
    try {
      await CampaignsService.uploadBase(formData, data.type);
      AlertService.toastSuccess(
        "¡ Éxito !, este proceso se realizó correctamente.",
      );
      onRequestClose(true);
      reset(defaultValues);
    } catch (error) {
      console.log(error);
      AlertService.toastError(
        "¡ Atención !,  este proceso no realizó correctamente, contacte al administrador.",
      );
    }
  };

  useEffect(() => {
    getDialers();
    if (payload) {
      reset({
        _id: payload?._id || defaultValues._id,
        name: payload?.name ? payload.name : defaultValues.name,
        type: payload?.type ? payload.type : defaultValues.type,
      });
    }
  }, [payload]);

  return (
    <div className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div
        className={`modal-dialog ${payload?.type === "filtered" ? "modal-xl" : "modal-lg"}`}
      >
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => closeModal()}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <form onSubmit={handleSubmit(submit)}>
            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
              <div className="mt-1 text-center">
                <h2>
                  {payload?.type === "update"
                    ? "Actualizar base de la campaña"
                    : payload?.type === "filtered"
                      ? "Sube tu base filtrada a la campaña"
                      : "Anexa nueva base a la campaña"}{" "}
                  <a className="link-primary fw-bold ms-2">{payload?.name}</a>
                </h2>
                <div className="text-muted fw-semibold fs-5">
                  Los campos marcados con <span className="required" /> son{" "}
                  <a className="link-danger fw-bold ms-2" href="#">
                    Obligatorios
                  </a>
                  .
                </div>
                <div className="separator border-2 my-5"></div>
              </div>
              <div className="row mt-10">
                <div
                  className={`${payload?.type === "filtered" ? "col-lg-6" : "col-lg-8"} mb-10 mb-lg-0 h-100 p-10`}
                >
                  <div className="fv-row">
                    <label className="d-flex flex-stack mb-7">
                      <span className="d-flex align-items-center me-2">
                        <span className="symbol symbol-circle symbol-50px me-6">
                          <span className="symbol-label bg-light-primary">
                            <i className="ki-duotone ki-add-files fs-2x text-primary">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                            </i>
                          </span>
                        </span>
                        <span className="d-flex flex-column">
                          <h4 className="fw-bold required">Cargar archivo</h4>
                          <div className="text-muted fw-semibold fs-7">
                            El formato del archivo debe ser en{" "}
                            {payload?.type === "filtered" ? (
                              <span>CSV.</span>
                            ) : (
                              <>
                                <span>TXT.</span>
                              </>
                            )}
                          </div>
                        </span>
                      </span>
                    </label>
                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                      {!file ? (
                        <Dropzone
                          onChange={updateFiles}
                          className="dropzone cursor-default"
                          accept={`${payload?.type === "filtered" ? ".csv" : ".txt"}`}
                          maxFiles={1}
                        >
                          <div className="dz-mkssage">
                            <>
                              <i className="ki-duotone ki-file-up fs-5x text-primary me-4">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                              <div className="d-flex flex-center flex-grow-1 flex-wrap flex-md-nowrap">
                                <div className="mb-3 mb-md-0 fw-semibold">
                                  <h5 className="text-gray-900 fw-bold">
                                    Arrastra tu archivo o haz clic para subirlo.
                                  </h5>
                                  <div className="fs-7 pe-7 text-info">
                                    {payload?.type === "filtered" ? (
                                      <span> Carga un archivo CSV.</span>
                                    ) : (
                                      <>
                                        <span> Carga un archivo TXT.</span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                        </Dropzone>
                      ) : (
                        <div className="dropzone cursor-default">
                          <div className="dz-g">
                            <i className="ki-duotone ki-file-added text-primary fs-5x text-primary me-4">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                            <div className="d-flex flex-column align-items-center flex-grow-1">
                              <div className="mb-3 mb-md-0 fw-semibold text-center">
                                <h5 className="text-gray-900 fw-bold">
                                  Archivo cargado con éxito.
                                </h5>
                                <div className="fs-7 text-info">
                                  {typeof file === "object" &&
                                  file !== null &&
                                  "name" in file
                                    ? (file as File).name
                                    : "No hay archivo cargado"}
                                </div>
                              </div>
                              <a
                                className="btn btn-danger px-6 text-nowrap btn-sm mt-3"
                                href="#"
                                onClick={removeFile}
                              >
                                Eliminar
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {payload?.type !== "update" && (
                    <div className="fv-row">
                      <label className="form-label">
                        Dialer
                        <span className="fs-8 text-muted ms-1">(Opcional)</span>
                      </label>
                      <Controller
                        control={control}
                        name="dailer"
                        rules={{ required: false }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <Select
                              options={dialerOptions}
                              noOptionsMessage={() => (
                                <div className="empty-select">
                                  {"No hay opciones disponibles"}
                                </div>
                              )}
                              className="form-select-custom"
                              classNamePrefix="form-select-custom"
                              placeholder="Selecciona un dialer"
                              isClearable
                              onChange={(
                                selectedOption:
                                  | {
                                      value: string;
                                      label: string;
                                    }
                                  | "",
                              ) => {
                                onChange(
                                  selectedOption ? selectedOption.value : "",
                                );
                              }}
                              value={
                                dialerOptions?.find(
                                  (dialer: any) => dialer.value === value,
                                ) || ""
                              }
                            />
                            <InvalidFeedback error={error} />
                          </>
                        )}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={`${payload?.type === "filtered" ? "col-lg-6" : "col-lg-4"} bg-light rounded p-10 d-flex flex-column justify-content-center align-items-center text-center`}
                >
                  <span className="symbol symbol-circle symbol-50px mb-6">
                    <span className="symbol-label bg-light-warning">
                      <i className="ki-duotone ki-information-3 fs-2x text-warning">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                    </span>
                  </span>
                  <h4 className="fw-bold mb-4">¡Atención!</h4>
                  <p className="text-muted fw-semibold text-justify">
                    {payload?.type === "filtered"
                      ? "Sube el archivo que descargaste previamente desde el botón 'Registros de campaña' en acciones. Asegúrate de haberlo filtrado antes de cargarlo en esta sección."
                      : "Debes agregar un archivo separado por PIPES (|) o espacios."}
                  </p>
                  <span className="text-muted fw-semibold text-justify mb-3">
                    Ejemplo:
                  </span>
                  <img
                    title="image-example"
                    src={`/assets/media/examples/${payload?.type === "filtered" ? "filtered_example.gif" : payload?.type === "update" ? "txt_example.png" : "txt_example_2.png"}`}
                    className={`img-fluid rounded border border-dark border-3`}
                    width={"550px"}
                  />
                </div>
              </div>

              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button
                  type="button"
                  className="btn btn-light hover-scale"
                  onClick={() => resetForm()}
                >
                  Cerrar
                </button>
                <Button
                  type="submit"
                  className="btn btn-primary ms-3 hover-scale"
                  disabled={!file || !isValid}
                  isLoading={isSubmitting}
                >
                  {payload?.type === "filtered"
                    ? "Subir base"
                    : payload?.type === "update"
                      ? "Actualizar base"
                      : "Anexa Base"}
                  <i className="ki-duotone ki-send ms-2 fs-1">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BaseModal;
