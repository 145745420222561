import { API_ENDPOINT } from "../constants";
import { HTTP } from "./http.service";

export const ReportsServices = {
  getCampaigns: () => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}reports/campaigns`);
  },

  getRegisters: (start_date: string, end_date: string, campaign: string) => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}reports`, {
      params: {
        start_date,
        end_date,
        campaign,
      },
    });
  },

  downloadFile: (start_date: string, end_date: string, campaign: string) => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}reports/download`, {
      params: {
        start_date,
        end_date,
        campaign,
      },
    });
  },
};
