import React, { useCallback } from "react";

interface InvalidFeedbackProps {
  error: any;
  className?: string;
  validatePassword?: boolean;
}

function InvalidFeedback({
  error,
  className = "",
  validatePassword,
}: InvalidFeedbackProps) {
  const getType = useCallback(() => {
    if (validatePassword && error?.type === "validate") {
      return "Las contraseñas no coinciden";
    }
    switch (error?.type) {
      case "required":
        return "Este campo es obligatorio.";
      case "maxLength":
        return `Sólo se permiten máximo ${error.message} caracteres.`;
      case "minLength":
        return `Se requiere un mínimo de ${error.message} caracteres.`;
      case "min":
        return `${error.message}`;
      case "max":
        return `${error.message}`;
      case "custom":
        return `${error.message}`;
      case "pattern":
        return `${error.message}`;
      default:
        return "Este campo es obligatorio.";
    }
  }, [error?.type, validatePassword]);

  return error ? (
    <span className={`invalid-feedback ${className}`}>{getType()}</span>
  ) : null;
}

export default InvalidFeedback;
