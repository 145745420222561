import { Link, useLocation } from "react-router-dom";
import HasAccess from "../../guard/has-access-view";
import { LocalStorageService } from "../../services/local-storage.service";
import { useState, useEffect } from "react";
import { STORAGE } from "../../constants";

type ProfileName = "Supervisor" | "Reportería";

function Aside() {
  const location = useLocation();
  const [profile, setProfile] = useState("");
  const routes: Record<ProfileName, string> = {
    Supervisor: "/sendCampaigns",
    Reportería: "/reportsCampaigns",
  };

  useEffect(() => {
    const session = LocalStorageService.get(STORAGE.SESSION);
    if (session) {
      const path = routes[session.profile.name as ProfileName];
      if (path) {
        setProfile(path);
      }
    }
  }, []);

  return (
    <>
      <div
        id="kt_aside"
        className="aside aside-default aside-hoverable"
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_toggle"
      >
        <div
          className="aside-logo flex-column-auto px-10 pt-9 pb-5"
          id="kt_aside_logo"
        >
          <Link to={profile}>
            <img
              alt="Logo"
              src="/assets/media/logos/logo.png"
              className="max-h-50px w-80px logo-default theme-light-show"
            />
            <img
              alt="Logo"
              src="/assets/media/logos/logoLetter.png"
              className="max-h-75px w-50px logo-minimize theme-light-show"
            />
            <img
              alt="Logo"
              src="/assets/media/logos/logoDark.png"
              className="max-h-50px w-80px logo-default theme-dark-show"
            />
            <img
              alt="Logo"
              src="/assets/media/logos/logoLetterDark.png"
              className="max-h-75px w-50px logo-minimize theme-dark-show"
            />
          </Link>
        </div>
        <div className="aside-menu flex-column-fluid ps-3 pe-1">
          <div
            className="menu menu-sub-indention menu-column menu-rounded menu-title-gray-600 menu-icon-gray-500 menu-active-bg menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 my-5 mt-lg-2 mb-lg-0"
            id="kt_aside_menu"
            data-kt-menu="true"
          >
            <div
              className="hover-scroll-y mx-4"
              id="kt_aside_menu_wrapper"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-height="auto"
              data-kt-scroll-wrappers="#kt_aside_menu"
              data-kt-scroll-offset="20px"
              data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            >
              <HasAccess permission="campaigns">
                <div className="menu-item pt-5">
                  <div className="menu-content">
                    <span className="fw-bold text-muted text-uppercase fs-7">
                      Campañas
                    </span>
                  </div>
                </div>
                <div className="menu-item">
                  <Link
                    className={
                      `menu-link ` +
                      (location.pathname === "/sendCampaigns" ||
                      location.pathname === "/"
                        ? "active"
                        : "")
                    }
                    to="/sendCampaigns"
                  >
                    <span className="menu-icon">
                      <span className="menu-icon">
                        <i className="ki-duotone ki-send fs-2">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      </span>
                    </span>
                    <span className="menu-title">Envío campañas</span>
                  </Link>
                </div>
                <div className="menu-item">
                  <Link
                    className={
                      `menu-link ` +
                      (location.pathname === "/listCampaigns" ? "active" : "")
                    }
                    to="/listCampaigns"
                  >
                    <span className="menu-icon">
                      <span className="menu-icon">
                        <i className="ki-duotone ki-tablet-book fs-2">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      </span>
                    </span>
                    <span className="menu-title">Mis campañas</span>
                  </Link>
                </div>
              </HasAccess>
              <HasAccess permission="reports">
                <div className="menu-item pt-5">
                  <div className="menu-content">
                    <span className="fw-bold text-muted text-uppercase fs-7">
                      Reportes
                    </span>
                  </div>
                </div>
                <div className="menu-item">
                  <Link
                    className={
                      `menu-link ` +
                      (location.pathname === "/reportsCampaigns" ||
                      location.pathname === "/"
                        ? "active"
                        : "")
                    }
                    to="/reportsCampaigns"
                  >
                    <span className="menu-icon">
                      <span className="menu-icon">
                        <i className="ki-duotone ki-graph-up fs-2">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                          <span className="path4"></span>
                          <span className="path5"></span>
                          <span className="path6"></span>
                        </i>
                      </span>
                    </span>
                    <span className="menu-title">Campañas</span>
                  </Link>
                </div>
              </HasAccess>
              <HasAccess permission="users">
                <div className="menu-item pt-5">
                  <div className="menu-content">
                    <span className="fw-bold text-muted text-uppercase fs-7">
                      Usuarios
                    </span>
                  </div>
                </div>
                <div className="menu-item">
                  <Link
                    className={
                      `menu-link ` +
                      (location.pathname === "/users" ? "active" : "")
                    }
                    to="/users"
                  >
                    <span className="menu-icon">
                      <span className="menu-icon">
                        <i className="ki-duotone ki-profile-user fs-2">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                          <span className="path4"></span>
                        </i>
                      </span>
                    </span>
                    <span className="menu-title">Usuarios</span>
                  </Link>
                </div>
              </HasAccess>
              <HasAccess permission="blacklist">
                <div className="menu-item pt-5">
                  <div className="menu-content">
                    <span className="fw-bold text-muted text-uppercase fs-7">
                      Herramientas
                    </span>
                  </div>
                </div>
                <div className="menu-item">
                  <Link
                    className={
                      `menu-link ` +
                      (location.pathname === "/blacklist" ? "active" : "")
                    }
                    to="/blacklist"
                  >
                    <span className="menu-icon">
                      <i className="ki-duotone ki-shield fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </span>
                    <span className="menu-title">Lista negra</span>
                  </Link>
                </div>
              </HasAccess>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Aside;
