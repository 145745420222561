import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Login } from "../../services/login.service";
import { STORAGE } from "../../constants";
import { LocalStorageService } from "../../services/local-storage.service";
import BgBody from "../../components/layout/bg-body.component";
import Input from "../../components/forms/input.component";
import Button from "../../components/forms/button.component";
import style from "./style.module.scss";
import BgPages from "../../hooks/bg-pages.hook";

declare const KTComponents: any;
type ProfileName = "Supervisor" | "Reportería";

function AuthPage() {
  BgPages();
  const navigate = useNavigate();
  const routes: Record<ProfileName, string> = {
    Supervisor: "/sendCampaigns",
    Reportería: "/reportsCampaigns",
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      username: "",
      password: "",
      correo: "",
    },
  });

  const [errorMessage, seterrorMessage] = useState("");
  const [typeAuth, setTypeAuth] = useState("login");

  const phrases = [
    {
      text: '"No importa lo lento que vayas, siempre y cuando no te detengas."',
      author: "Confucio",
    },
    {
      text: '"El éxito es la suma de pequeños esfuerzos repetidos día tras día."',
      author: "Robert Collier",
    },
    {
      text: '"El camino hacia el éxito yace en el aprendizaje mediante la experiencia."',
      author: "Colin Powell",
    },
    {
      text: '"El único lugar donde el éxito viene antes que el trabajo es en el diccionario."',
      author: "Vidal Sassoon",
    },
    {
      text: '"Si haces algo que realmente te gusta, no tendrás que trabajar un solo día en tu vida."',
      author: "Steve Jobs",
    },
  ];

  const getRandomItem = (array: { text: string; author: string }[]) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  };

  const randomPhrase = getRandomItem(phrases);

  const login = async (data: any) => {
    try {
      const response = await Login.login(data);
      LocalStorageService.save(STORAGE.TOKEN, response.token);
      LocalStorageService.save(STORAGE.SESSION, response.user);
      const path = routes[response.user.profile.name as ProfileName];
      if (path) {
        navigate(path);
      }
    } catch (error: any) {
      console.log("Atención error: ", error);
      seterrorMessage(error);
    }
  };

  const recoverPassword = async (data: any) => {
    try {
      const response = await Login.login(data);
      LocalStorageService.save(STORAGE.TOKEN, response.access_token);
      LocalStorageService.save(STORAGE.SESSION, response.user);
      navigate("/sendCampaigns");
    } catch (error: any) {
      console.log("LoginPage.login.error::", error);
      seterrorMessage(error);
    }
  };

  const handleAlertClose = () => {
    seterrorMessage("");
  };

  useEffect(() => {
    const session = LocalStorageService.get(STORAGE.SESSION);
    if (session) {
      const path = routes[session.profile.name as ProfileName];
      if (path) {
        navigate(path);
      }
    }
  }, [navigate]);

  useEffect(() => {
    KTComponents.init();
  });

  return (
    <>
      <BgBody />
      <div
        className="d-flex flex-column flex-lg-row flex-column-fluid"
        id="module-auth-login"
      >
        <div className="d-flex flex-column flex-lg-row-auto w-xl-650px position-xl-relative">
          <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-650px scroll-y bg-dark">
            <div className="d-flex justify-content-between align-items-start w-100 p-2">
              <img
                alt="Logo"
                src="/assets/media/logos/logoLetterDark.png"
                className="h-45px h-lg-45px logo-default mb-5 d-none d-lg-block"
              />
            </div>
            <div className="d-flex flex-grow-1 align-items-center justify-content-center position-relative">
              <div
                className={`d-none d-lg-block d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-450px h-lg-450px ${style.dotlottie}`}
              >
                <img
                  src="/assets/media/illustrations/front/crm.png"
                  alt=""
                  width={"400px"}
                />
              </div>
            </div>
            <div className={`ms-3 ${style.phraseWidth}`}>
              <figure>
                <blockquote className="blockquote">
                  <p className="mb-8 text-white opacity-50">
                    {randomPhrase.text}
                  </p>
                  <figcaption className="blockquote-footer text-white opacity-50 ">
                    <span className="text-white opacity-50 fs-14">
                      {randomPhrase.author}
                    </span>
                  </figcaption>
                </blockquote>
              </figure>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-lg-row-fluid py-10">
          <div className="d-flex flex-center flex-column flex-column-fluid">
            <div className="w-lg-500px p-10 p-lg-15 mx-auto">
              <div
                className="form w-100"
                id="kt_sign_in_form"
                data-kt-redirect-url="/auth/login"
              >
                <div className="text-center mb-5">
                  <img
                    alt="Logo"
                    src="/assets/media/logos/logoDark.png"
                    className="h-100px h-lg-200px logo-default theme-dark-show mb-5"
                  />
                  <img
                    alt="Logo"
                    src="/assets/media/logos/logo.png"
                    className="h-100px h-lg-175px logo-default theme-light-show mb-5"
                  />
                  {typeAuth === "login" ? (
                    <>
                      <h1 className="text-gray-900 mb-3 fs-2qx">
                        Inicio de Sesión
                      </h1>
                      <div className="text-gray-500 fw-semibold fs-4">
                        Ingresa a tu cuenta
                      </div>
                    </>
                  ) : (
                    <>
                      <h1 className="text-gray-900 mb-3 fs-2qx">
                        Olvidaste tu contraseña ?
                      </h1>
                      <div className="text-gray-500 fw-semibold fs-4">
                        Ingresa tu correo electrónico registrado en tu cuenta y
                        te enviaremos instrucciones para restablecer tu
                        contraseña.
                      </div>
                    </>
                  )}
                </div>
                {typeAuth === "login" ? (
                  <>
                    <form
                      className="form"
                      onSubmit={handleSubmit((data) => {
                        login(data);
                      })}
                    >
                      {errorMessage && (
                        <div className="alert alert-danger d-flex align-items-center p-2">
                          <i className="ki-duotone ki-shield-cross fs-2hx text-danger me-4">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                          </i>
                          <div className="d-flex flex-column">
                            <h4 className="mb-1">¡ Atención !</h4>
                            <span>{errorMessage}</span>
                          </div>
                          <a
                            className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                            onClick={handleAlertClose}
                          >
                            <i className="ki-duotone ki-cross fs-1 text-danger">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </a>
                        </div>
                      )}
                      <div className="fv-row mb-10">
                        <Input
                          requiredIndicator="required"
                          label={"Usuario"}
                          placeholder={"Ingresa tu usuario"}
                          errors={errors.username}
                          {...register("username", { required: true })}
                        />
                      </div>
                      <div className="fv-row mb-10">
                        <Input
                          requiredIndicator="required"
                          label={"Contraseña"}
                          placeholder={"Ingresa tu contraseña"}
                          type="password"
                          errors={errors.password}
                          {...register("password", { required: true })}
                        />
                      </div>
                      <div className="text-center">
                        <Button
                          type="submit"
                          className="btn btn-lg btn-primary w-100 mb-5"
                          disabled={!isValid}
                          isLoading={isSubmitting}
                          onClick={handleSubmit(login)}
                        >
                          Inicio de sesión
                        </Button>
                      </div>
                    </form>
                  </>
                ) : (
                  <>
                    <form
                      className="form"
                      onSubmit={handleSubmit((data) => {
                        recoverPassword(data);
                      })}
                    >
                      {errorMessage && (
                        <div className="alert alert-danger d-flex align-items-center p-2">
                          <i className="ki-duotone ki-shield-cross fs-2hx text-danger me-4">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                          </i>
                          <div className="d-flex flex-column">
                            <h4 className="mb-1">¡ Atención !</h4>
                            <span>{errorMessage}</span>
                          </div>
                          <a
                            className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                            onClick={handleAlertClose}
                          >
                            <i className="ki-duotone ki-cross fs-1 text-danger">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </a>
                        </div>
                      )}
                      <div className="fv-row mb-10">
                        <Input
                          requiredIndicator="required"
                          label={"Correo"}
                          placeholder={"Ingresa tu correo"}
                          errors={errors.username}
                          {...register("correo", { required: true })}
                        />
                      </div>
                      <div className="text-center">
                        <Button
                          type="submit"
                          className="btn btn-lg btn-primary w-100 mb-8"
                          disabled={!isValid}
                          isLoading={isSubmitting}
                          onClick={handleSubmit(login)}
                        >
                          Enviar
                        </Button>
                      </div>
                    </form>
                  </>
                )}
                <div className="d-flex flex-stack">
                  <div className="me-5">
                    {typeAuth === "login" ? <></> : <></>}
                  </div>
                  <a
                    className="btn btn-active-light-primary"
                    data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                    data-kt-menu-placement="bottom-end"
                    title="theme"
                  >
                    <i className="ki-duotone ki-night-day theme-light-show fs-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                      <span className="path5"></span>
                      <span className="path6"></span>
                      <span className="path7"></span>
                      <span className="path8"></span>
                      <span className="path9"></span>
                      <span className="path10"></span>
                    </i>
                    <i className="ki-duotone ki-moon theme-dark-show fs-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="menu-title opacity-50 theme-light-show">
                      Claro
                    </span>
                    <span className="menu-title opacity-50 theme-dark-show">
                      Oscuro
                    </span>
                  </a>
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                    data-kt-menu="true"
                    data-kt-element="theme-mode-menu"
                  >
                    <div className="menu-item px-3 my-0">
                      <a
                        href="#"
                        className="menu-link px-3 py-2 active"
                        data-kt-element="mode"
                        data-kt-value="light"
                      >
                        <span className="menu-icon" data-kt-element="icon">
                          <i className="ki-duotone ki-night-day fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                            <span className="path5"></span>
                            <span className="path6"></span>
                            <span className="path7"></span>
                            <span className="path8"></span>
                            <span className="path9"></span>
                            <span className="path10"></span>
                          </i>
                        </span>
                        <span className="menu-title">Claro</span>
                      </a>
                    </div>
                    <div className="menu-item px-3 my-0">
                      <a
                        href="#"
                        className="menu-link px-3 py-2"
                        data-kt-element="mode"
                        data-kt-value="dark"
                      >
                        <span className="menu-icon" data-kt-element="icon">
                          <i className="ki-duotone ki-moon fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                        <span className="menu-title">Oscuro</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AuthPage;
