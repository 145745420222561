import { Link } from "react-router-dom";
import Header from "../../components/layout/header.component";
import Asaid from "../../components/layout/asaid.component";
import Footer from "../../components/layout/footer.component";
import useBgPages from "../../hooks/bg-pages.hook";
import { LocalStorageService } from "../../services/local-storage.service";
import { useState, useEffect } from "react";
import { STORAGE } from "../../constants";

type ProfileName = "Supervisor" | "Reportería";

function NotFoundPage() {
  useBgPages();
  const [profile, setProfile] = useState("");
  const routes: Record<ProfileName, string> = {
    Supervisor: "/sendCampaigns",
    Reportería: "/reportsCampaigns",
  };

  useEffect(() => {
    const session = LocalStorageService.get(STORAGE.SESSION);
    if (session) {
      const path = routes[session.profile.name as ProfileName];
      if (path) {
        setProfile(path);
      }
    }
  }, []);
  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Asaid />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <Header />
          <div
            className="content fs-6 d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl d-flex justify-content-center align-items-center">
                <div className="d-flex flex-column flex-center p-10 text-center">
                  <img
                    src="/assets/media/illustrations/sigma-1/22.png"
                    alt=""
                    className="img-fluid mw-100 mh-175px mb-3"
                  />
                  <h1 className="fs-4qx text-danger mb-5">oops!</h1>
                  <h1 className="fs-1qx mb-5">Página no encontrada</h1>
                  <div className="mb-10 ms-1 fs-6 text-break w-400x">
                    <span>
                      Lamentamos informarte que la página que estás buscando no
                      existe o ha sido removida.
                      <br />
                      Te invitamos a regresar a la página principal o a
                      continuar navegando a través del siguiente enlace.
                    </span>
                  </div>
                  <Link
                    className="btn btn-lg btn-primary w-150px w-lg-175px mb-5"
                    to={profile}
                  >
                    <i className="ki-duotone ki-double-left fs-2x">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                      <span className="path6" />
                    </i>
                    Regresar
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default NotFoundPage;
