import { useEffect } from "react";

const BgBody = () => {
  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("auth-bg");

    const root = document.getElementById("root");
    root?.classList.add("d-flex", "flex-column", "flex-root");

    return () => {
      body.classList.remove("auth-bg");
      root?.classList.remove("d-flex", "flex-column", "flex-root");
    };
  }, []);

  return null;
};

export default BgBody;
