import { useEffect, useState } from "react";
import Aside from "../../components/layout/asaid.component";
import Header from "../../components/layout/header.component";
import Footer from "../../components/layout/footer.component";
import useBgPages from "../../hooks/bg-pages.hook";
import DataTable from "../../components/datatable/datatable.component";
import useRequest from "../../hooks/use-request.hook";
import AddBlackList from "./add-blacklist-modal";
import EditBlackList from "./edit-blacklist-modal";
import { BlackListService } from "../../services/blacklist.service";
import { AlertService } from "../../services/alert.service";
import moment from "moment";

function BlackListPage() {
  useBgPages();
  const { call, isRequesting } = useRequest();
  const [rows, setRows] = useState([]);
  const [blacklists, setBlacklists] = useState(0);
  const columns = [
    {
      name: "Nombre",
      sortable: true,
      cell: (rows: any) => {
        return (
          <div className="text-nowrap">
            <span className="badge badge-dark">{rows.name}</span>
          </div>
        );
      },
    },
    {
      name: "Fecha de Registro",
      cell: (rows: any) => {
        const fechaHora = moment(rows.created_at);
        const fecha = fechaHora.format("DD-MM-YYYY");
        const hora = fechaHora.format("HH:mm:ss");

        return (
          <div className="text-nowrap">
            <h5>
              Fecha: <span className="text-muted">{fecha}</span>
            </h5>
            <h5>
              Hora: <span className="text-muted">{hora}</span>
            </h5>
          </div>
        );
      },
    },
    {
      name: "Status",
      sortable: false,
      center: true,
      exportable: false,
      cell: (rows: any) =>
        rows.status ? (
          <span className="badge badge-light-primary fs-6">
            <i className="ki-duotone ki-check-circle text-primary fs-3 me-1">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            Activo
          </span>
        ) : (
          <span className="badge badge-light-danger fs-6">
            <i className="ki-duotone ki-minus-circle text-danger fs-3 me-1">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            Inactivo
          </span>
        ),
    },
    {
      name: "Acciones",
      sortable: false,
      center: true,
      exportable: false,
      cell: (rows: any) => {
        return (
          <>
            <div className="text-nowrap">
              <button
                className="btn btn-light-primary btn-sm btn-flex btn-center"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                type="button"
              >
                Acciones
                <i className="ki-duotone ki-down fs-5 ms-1" />
              </button>
              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                data-kt-menu="true"
              >
                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    href="#"
                    onClick={() => changeStatus(rows._id, rows.status)}
                  >
                    {!rows.status ? (
                      <>
                        <i className="ki-duotone ki-check-circle fs-2x text-primary">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                        <span className="ms-2">Activar</span>
                      </>
                    ) : (
                      <>
                        <i className="ki-duotone ki-cross-square fs-2x text-danger">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                        <span className="ms-2">Desactivar</span>
                      </>
                    )}
                  </a>
                </div>
                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    href="#"
                    onClick={() => setShowModalEdit({ show: true, data: rows })}
                  >
                    <i className="ki-duotone ki-notepad-edit fs-2x text-primary">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="ms-2">Editar</span>
                  </a>
                </div>
                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    href="#"
                    onClick={() => deleteBlackList(rows._id)}
                  >
                    <i className="ki-duotone ki-trash fs-2x text-danger">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                      <span className="path5"></span>
                    </i>
                    <span className="ms-2">Eliminar</span>
                  </a>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const getBlackList = async () => {
    const data = await call(BlackListService.getBlackLists);
    setRows(data);
    setBlacklists(data.length);
  };

  const changeStatus = async (id: string, status: boolean) => {
    status = !status;
    try {
      const confirmed = await AlertService.warning(
        "¡Alerta!",
        `Estás a punto de ${status ? "activar" : "desactivar"} la lista negra. ¿Estás seguro? Puedes revertir esta acción`,
        null as any,
        status ? "Activar" : "Desactivar",
      );
      if (!confirmed) {
        return;
      }
      await BlackListService.changeStatus(id, status);
      AlertService.success("¡Éxito!", "Lista negra actualizada correctamente.");
      getBlackList();
    } catch (error) {
      console.log(error);
    }
  };
  const deleteBlackList = async (id: string) => {
    try {
      const confirmed = await AlertService.warning(
        "¡Alerta!",
        "¿Estás seguro de eliminar esta lista negra? <br> Esta acción no se puede deshacer.",
        null as any,
        "Eliminar",
      );
      if (!confirmed) {
        return;
      }
      await BlackListService.deleteBlackList(id);
      AlertService.success("¡Éxito!", "Lista negra eliminada correctamente.");
      getBlackList();
    } catch (error) {
      console.error(error);
    }
  };

  const [showModalEdit, setShowModalEdit] = useState({
    show: false,
    data: null,
  });

  const handleShowModalEdit = async (refresh: boolean) => {
    if (refresh) {
      getBlackList();
    }
    setShowModalEdit({ show: false, data: null });
  };

  const [showModal, setShowModal] = useState({
    show: false,
  });

  const handleShowModal = async (refresh: boolean) => {
    if (refresh) {
      getBlackList();
    }
    setShowModal({ show: false });
  };
  useEffect(() => {
    getBlackList();
  }, []);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Aside />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <Header />
          <div
            className="content fs-6 d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">
                      Herramientas /
                    </li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-shield fs-3x">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <span className="ms-3">Lista Negra</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="row gy-5 g-xl-10">
                  <div className="col-xl-4 mb-xl-10">
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">
                            Estadísticas
                          </span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            Lista Negra
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <span className="btn btn-clean btn-sm btn-icon btn-icon-primary me-n3">
                            <i className="ki-duotone ki-category fs-1 text-primary">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                          </span>
                        </div>
                      </div>
                      <div className="card-body pt-1">
                        <div className="d-flex flex-stack mb-2 item-border-hover px-3 py-2 ms-n3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-shield fs-3x text-info w-35px me-6 hover-scale">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <div className="pe-2">
                              <a
                                title="s"
                                className="fs-5 text-gray-800 text-hover-info fw-bolder"
                                href="#"
                              >
                                Listas negras
                              </a>
                              <div className="text-gray-400 fw-semibold mt-1">
                                Mis listas negras
                              </div>
                            </div>
                          </div>
                          <span className="label rounded bg-light w-25px h-25px fs-5 fw-bolder ">
                            {blacklists}
                          </span>
                        </div>
                        <div className="d-flex align-items-center flex-nowrap text-nowrap py-1">
                          <a
                            className="btn btn-primary hover-scale"
                            onClick={() => setShowModal({ show: true })}
                          >
                            <i className="ki-duotone ki-abstract-10 fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            Agregar Lista Negra
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 mb-5 mb-xl-10">
                    <div className="row g-5 g-xl-10 h-xxl-50 mb-0 mb-xl-10">
                      <div className="card mb-5 mb-xxl-8">
                        <div className="card-header align-items-center border-0 mt-5">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="fw-bolder fw-bold fs-2">
                              Lista Negra
                            </span>
                            <span className="text-gray-400 mt-2 fw-semibold fs-6">
                              Registradas
                            </span>
                          </h3>
                        </div>
                        <div className="card-body pt-1">
                          <DataTable
                            columns={columns}
                            data={rows}
                            isLoading={isRequesting}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <AddBlackList
        show={showModal.show}
        onRequestClose={(refresh: any) => handleShowModal(refresh)}
      />
      <EditBlackList
        show={showModalEdit.show}
        onRequestClose={(refresh: any) => handleShowModalEdit(refresh)}
        payload={showModalEdit.data}
      />
    </>
  );
}

export default BlackListPage;
