import { useState } from "react";

function useRequest() {
  const [isRequesting, setIsRequesting] = useState(false);

  const call = async (req: any): Promise<typeof req> => {
    try {
      setIsRequesting(true);
      const data = await req();

      return data;
    } catch (error) {
      throw Error(String(error));
    } finally {
      setIsRequesting(false);
    }
  };

  return { call, isRequesting };
}
export default useRequest;
