import { LocalStorageService } from "./services/local-storage.service";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { PROFILES, STORAGE } from "./constants";
import { Toaster } from "react-hot-toast";
import ReactDOM from "react-dom/client";
import AuthPage from "./pages/auth/login.page";
import NotFoundPage from "./pages/errors/not-found.page";
import useBgPages from "./hooks/bg-pages.hook";
import UsersPage from "./pages/users/users.page";
import CampaignsPage from "./pages/campaings/campaigns.page";
import BlackListPage from "./pages/blacklist/blacklist.page";
import CampaignsListPage from "./pages/campaings/campaigns.list.page";
import AgentsPage from "./pages/agents/agents.page";
import "./index.scss";
import "./i18/i18";
import HasAccessRoute from "./guard/has-access-route";
import RerpotsCampaigns from "./pages/report/campaigns-page";

const PrivateRoute = () => {
  const session = LocalStorageService.get(STORAGE.SESSION);
  const token = LocalStorageService.get(STORAGE.TOKEN);

  if (!session || !token) {
    return <Navigate to="/auth/login" replace />;
  }

  return <Outlet />;
};

const LayoutRoute = () => {
  useBgPages();

  return (
    <>
      <Outlet />
    </>
  );
};

const Layout = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/auth">
            <Route path="login" element={<AuthPage />} />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<LayoutRoute />}>
              <Route path="/" element={<CampaignsPage />} />
              <Route
                path="/users"
                element={<HasAccessRoute permission="users" />}
              >
                <Route path="" element={<UsersPage />} />
              </Route>
              <Route
                path="/sendCampaigns"
                element={<HasAccessRoute permission="campaigns" />}
              >
                <Route path="" element={<CampaignsPage />} />
              </Route>
              <Route
                path="/listCampaigns"
                element={<HasAccessRoute permission="campaigns" />}
              >
                <Route path="" element={<CampaignsListPage />} />
              </Route>
              <Route
                path="/reportsCampaigns"
                element={<HasAccessRoute permission="reports" />}
              >
                <Route path="" element={<RerpotsCampaigns />} />
              </Route>
              <Route
                path="/blacklist"
                element={<HasAccessRoute permission="blacklist" />}
              >
                <Route path="" element={<BlackListPage />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path="/iframe/form" element={<AgentsPage />} />
        </Routes>
      </BrowserRouter>
      <Toaster position="top-center" containerStyle={{ zIndex: 1 }} />
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(<Layout />);
