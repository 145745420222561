import { useEffect, useRef, useState } from "react";
import { AlertService } from "../../services/alert.service";
import { Controller, useForm } from "react-hook-form";
import { UsersService } from "../../services/users.service";
import { ProfileService } from "../../services/profiles.service";
import useInputValidation from "../../hooks/input-validation.hook";
import useModal from "../../hooks/close-modal.hook";
import Input from "../../components/forms/input.component";
import Select from "react-select";
import Button from "../../components/forms/button.component";
import InvalidFeedback from "../../components/forms/invalid-feedback.component";
import useBgPages from "../../hooks/bg-pages.hook";

declare const KTComponents: any;

interface AddUserProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
  payload: any;
}

function AddUser({ show, onRequestClose, payload }: AddUserProps) {
  useBgPages();
  const modalRef = useRef<HTMLDivElement>(null);
  const [progress, setProgress] = useState([false, false, false, false, false]);
  const [profiles, setProfiles] = useState<any[]>([]);

  const consecutiveTrueCount = progress.reduce((count, value) => {
    if (value) {
      count += 1;
    } else {
      return count;
    }
    return count;
  }, 0);

  const { passwordRegex, handleEightChars, handleSpaceReplacement } =
    useInputValidation();
  useModal(modalRef, show);

  const defaultValues = {
    _id: "",
    username: "",
    name: "",
    profile: "",
    id_ucontact: "",
    password: "",
    confirmPassword: "",
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const getProfiles = async () => {
    try {
      const data = await ProfileService.getProfiles();
      setProfiles(
        data.map((item: any) => ({
          value: item._id,
          label: item.name,
        })),
      );
    } catch (error: any) {
      AlertService.toastError(
        "¡ Error !, No pudimos obtener los perfiles.",
        error,
      );
    }
  };

  const submit = async (data: any) => {
    try {
      const { profile, id_ucontact, name, username, password } = data;
      const filteredData = { profile, id_ucontact, name, username, password };

      const isUpdate = payload !== null;
      const actionMessage = isUpdate
        ? `¿ Deseas actualizar la información de ${payload.username} ?`
        : `¿ Deseas registrar el nuevo usuario ?`;

      const successMessage = isUpdate
        ? "¡ Éxito !, Se actualizó la información del usuario correctamente."
        : "¡ Éxito !, Se registró el usuario correctamente.";

      const saveOrUpdateUser = async () => {
        try {
          if (isUpdate) {
            await UsersService.editUser(filteredData, payload._id);
          } else {
            await UsersService.saveUser(filteredData);
          }

          AlertService.toastSuccess(successMessage);
          onRequestClose(true);
          reset(defaultValues);
        } catch (error) {
          console.error(
            "Error en la operación de guardado o actualización:",
            error,
          );

          AlertService.toastWarning(
            `¡ Atención !, No se puede ${isUpdate ? "actualizar" : "registrar"} el usuario. ${error}.`,
          );
        }
      };

      await AlertService.question(
        actionMessage,
        "Verifica tu información antes de continuar.",
        saveOrUpdateUser,
      );
    } catch (error) {
      console.error(error);
      AlertService.toastError("¡ Error !, Contacta al administrador.");
    }
  };

  const validatePassword = (password: any) => {
    const lengthCriteria = password.length >= 8;
    const lowercaseCriteria = /[a-z]/.test(password);
    const uppercaseCriteria = /[A-Z]/.test(password);
    const numberCriteria = /[0-9]/.test(password);
    const specialCriteria = /[!@#$%¡¿/&*()_+{}<>?]/.test(password);
    setProgress([
      specialCriteria,
      numberCriteria,
      uppercaseCriteria,
      lowercaseCriteria,
      lengthCriteria,
    ]);
  };

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const password = event.target.value;
    await validatePassword(password);
    setValue("password", password, { shouldValidate: true });
    trigger("password");
  };

  const generatePassword = () => {
    const length = 8;
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const special = "!@#$%¡¿/&*()_+{}<>?";

    const allChars = lowercase + uppercase + numbers + special;
    let newPassword = "";

    newPassword += lowercase[Math.floor(Math.random() * lowercase.length)];
    newPassword += uppercase[Math.floor(Math.random() * uppercase.length)];
    newPassword += numbers[Math.floor(Math.random() * numbers.length)];
    newPassword += special[Math.floor(Math.random() * special.length)];

    for (let i = 4; i < length; i++) {
      newPassword += allChars[Math.floor(Math.random() * allChars.length)];
    }

    newPassword = newPassword
      .split("")
      .sort(() => 0.5 - Math.random())
      .join("");

    const typeText = (text: string, index = 0) => {
      if (index < text.length) {
        setValue("password", text.slice(0, index + 1), {
          shouldValidate: true,
        });
        setTimeout(() => typeText(text, index + 1), 50);
      } else {
        trigger("password");
      }
    };
    typeText(newPassword);
  };

  const closeModal = () => {
    onRequestClose(false);
    reset(defaultValues);
  };

  const resetForm = () => {
    onRequestClose(false);
    reset(defaultValues);
  };

  const selectedProfile = profiles.find(
    (option: any) => option.value === watch("profile"),
  );

  useEffect(() => {
    const subscription = watch((value) => {
      validatePassword(value.password);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (payload) {
      reset({
        _id: payload?._id || defaultValues._id,
        username: payload?.username ? payload.username : defaultValues.username,
        name: payload?.name ? payload.name : defaultValues.name,
        profile: payload?.profile._id
          ? payload.profile._id
          : defaultValues.profile,
        id_ucontact: payload?.id_ucontact
          ? payload.id_ucontact
          : defaultValues.id_ucontact,
        password: payload?.password ? payload.password : defaultValues.password,
        confirmPassword: payload?.confirmPassword
          ? payload.confirmPassword
          : defaultValues.confirmPassword,
      });
    }
  }, [payload]);

  useEffect(() => {
    getProfiles();
  }, []);

  useEffect(() => {
    if (selectedProfile?.label !== "Agente") {
      KTComponents.init();
    }
  }, [selectedProfile]);

  return (
    <div className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => resetForm()}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <form onSubmit={handleSubmit(submit)}>
            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
              <div className="mt-1 text-center">
                <h1 className="mb-3">
                  {watch("_id") ? (
                    <>
                      Editar el usuario
                      <a className="link-primary fw-bold ms-2" href="#">
                        {payload?.username}
                      </a>
                    </>
                  ) : (
                    <> Registra nuevo usuario</>
                  )}
                </h1>
                <div className="text-muted fw-semibold fs-5">
                  <span>
                    Los campos marcados con <span className="required"></span>{" "}
                    son <a className="link-danger fw-bold">Obligatorios</a>
                  </span>
                </div>
                <div className="separator border-2 my-5"></div>
              </div>
              <div className="row mt-10">
                <div className="col-lg-6 mb-10 mb-lg-0 h-100 p-10">
                  <label className="d-flex flex-stack mb-7">
                    <span className="d-flex align-items-center me-2">
                      <span className="symbol symbol-circle symbol-50px me-6">
                        <span className="symbol-label bg-light-primary">
                          <i className="ki-duotone ki-notepad-edit fs-2x text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </span>
                      <span className="d-flex flex-column">
                        <h2 className="fw-bold">Datos generales</h2>
                        <div className="text-muted fw-semibold fs-6">
                          Ingesa los datos necesarios.
                        </div>
                      </span>
                    </span>
                  </label>
                  <div className="fv-row mb-7">
                    <Input
                      label={"Nombre"}
                      requiredIndicator="required"
                      placeholder={"Ingresa nombre completo"}
                      errors={errors.name}
                      {...register("name", {
                        required: true,
                        setValueAs: (value) => value.trim(),
                      })}
                    />
                  </div>
                  <div className="fv-row mb-7">
                    <Input
                      label={"Usuario"}
                      requiredIndicator="required"
                      onKeyDown={handleSpaceReplacement}
                      placeholder={"Ingresa un usuario"}
                      errors={errors.username}
                      {...register("username", {
                        required: true,
                        setValueAs: (value) => value.trim(),
                      })}
                    />
                  </div>
                  <div className="fv-row mb-7">
                    <label className="form-label required">Perfil</label>
                    <Controller
                      control={control}
                      name="profile"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <Select
                            options={profiles}
                            noOptionsMessage={() => (
                              <div className="empty-select">
                                No hay opciones disponibles.
                              </div>
                            )}
                            className={
                              error
                                ? "form-select-custom is-invalid"
                                : "form-select-custom"
                            }
                            classNamePrefix={"form-select-custom"}
                            placeholder={"Selecciona el tipo de perfil"}
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                onChange(selectedOption.value);
                              } else {
                                onChange(null);
                              }
                            }}
                            value={
                              profiles.find(
                                (option: any) => option.value === value,
                              ) || null
                            }
                          />
                          <InvalidFeedback error={error} />
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 rounded h-100 p-10">
                  <label className="d-flex flex-stack mb-7">
                    <span className="d-flex align-items-center me-2">
                      <span className="symbol symbol-circle symbol-50px me-6">
                        <span className="symbol-label bg-light-info">
                          <i className="ki-duotone ki-gear fs-3x text-info">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </span>
                      <span className="d-flex flex-column">
                        <h2 className="fw-bold">
                          Configuración
                          <span className="required"></span>
                        </h2>
                        <div className="text-muted fw-semibold fs-6">
                          Genera una contraseña y no olvides ingresar tu ID.
                        </div>
                      </span>
                    </span>
                  </label>
                  {selectedProfile?.label === "Agente" ? (
                    <div className="fv-row mb-7">
                      <Input
                        label={"uContact ID"}
                        requiredIndicator="required"
                        placeholder={"Ingresa tu uContact ID"}
                        errors={errors.id_ucontact}
                        {...register("id_ucontact", {
                          required: true,
                          setValueAs: (value) => value.trim(),
                        })}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="fv-row mb-7">
                        <Input
                          label={"Contraseña"}
                          type={"password"}
                          optional={payload !== null}
                          requiredIndicator={
                            payload === null ? "required" : false
                          }
                          onKeyDown={handleEightChars}
                          placeholder={"Ingresa Contraseña"}
                          errors={errors.password}
                          {...register("password", {
                            required: payload === null,
                            setValueAs: (value) => value.trim(),
                            pattern: {
                              value: passwordRegex,
                              message: "Contraseña no válida",
                            },
                          })}
                          onChange={handleInputChange}
                        />
                        <div className="d-flex align-items-center mt-3 mb-3">
                          {progress.map((isActive, index) => (
                            <div
                              key={index}
                              className={`flex-grow-1 bg-secondary ${
                                index < consecutiveTrueCount
                                  ? "bg-active-primary"
                                  : ""
                              } ${index < consecutiveTrueCount ? "active" : ""} rounded h-5px ${
                                index < progress.length - 1 ? "me-2" : ""
                              }`}
                            />
                          ))}
                        </div>
                        <div className="text-muted">
                          Usa 8 caracteres, incluyendo al menos una minúscula,
                          una mayúscula, un número y un símbolo, o genera una
                          contraseña haciendo{" "}
                          <a
                            className="text-primary fw-bolder cursor-pointer"
                            onClick={() => generatePassword()}
                          >
                            Click aquí
                          </a>
                          .
                        </div>
                      </div>

                      <div className="fv-row mb-7">
                        <Input
                          label={"Confirmar contraseña"}
                          requiredIndicator={
                            payload === null ? "required" : false
                          }
                          optional={payload !== null}
                          type={"password"}
                          validatePassword
                          onKeyDown={handleEightChars}
                          placeholder={"Confirma tu contraseña"}
                          errors={errors.confirmPassword}
                          {...register("confirmPassword", {
                            required: payload === null,
                            setValueAs: (value) => value.trim(),
                            validate: (value) => {
                              if (value !== watch("password")) {
                                return "Las contraseñas no coinciden";
                              }
                              return true;
                            },
                          })}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button
                  type="button"
                  className="btn btn-light hover-scale"
                  onClick={() => closeModal()}
                >
                  Cerrar
                </button>
                <Button
                  type="submit"
                  id="sendData"
                  className="btn btn-primary ms-3 hover-scale"
                  disabled={!isValid}
                  isLoading={isSubmitting}
                  onClick={handleSubmit(submit)}
                >
                  {watch("_id") ? "Editar" : "Registrar"}
                  <i className="ki-duotone ki-send ms-2 fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
