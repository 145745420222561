import { API_ENDPOINT } from "../constants";
import { HTTP } from "./http.service";

export const AgentsService = {
  getCustomer: (register: string, ucontact_id: string, token: string) => {
    return HTTP.post<any, any>(
      `${API_ENDPOINT.CORE}agents/customer`,
      { register: register, ucontact_id: ucontact_id, token: token },
      {
        headers: {
          "ucontact-token":
            "39hUDJV8ZOEw8qZLuLgVu2lyDzQXRfeXEx7XS5p4VcwXiIKOOl",
        },
      },
    );
  },
  saveForm: (data: any) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}agents/form`, data, {
      headers: {
        "ucontact-token": "39hUDJV8ZOEw8qZLuLgVu2lyDzQXRfeXEx7XS5p4VcwXiIKOOl",
      },
    });
  },
};
