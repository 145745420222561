const env: any = process.env.REACT_APP_STAGE;

export const STAGES = {
  LOCAL: "LOCAL",
  DEV: "dev",
  PROD: "PROD",
};

let _API_ = {
  CORE: "https://develop.api-477etb.ws-voices.com/",
};

let _ENV_NAME_ = STAGES.LOCAL;
export const APPS = {
  CHANNEL_WHATSAPP: "channelWhatsApp",
};
switch (env) {
  case "dev":
    _API_ = {
      CORE: "https://develop.api-477etb.ws-voices.com/",
    };
    _ENV_NAME_ = STAGES.DEV;

    break;
  case "prod":
    _API_ = {
      CORE: "https://api-477etb.ws-voices.com/",
    };
    _ENV_NAME_ = STAGES.PROD;
    break;

  default:
    break;
}

export const API_ENDPOINT = _API_;
export const ENV_NAME = _ENV_NAME_;

export const STORAGE_PREFIX = "template-";

export const STORAGE = {
  TOKEN: STORAGE_PREFIX + "token",
  SESSION: STORAGE_PREFIX + "session",
};

export const PROFILES = {
  SUPERVISOR: "Supervisor",
  REPORTS: "Reportería",
  AGENTE: "Agente",
};
