import { Link } from "react-router-dom";
import styles from "./footer.module.scss";
import { LocalStorageService } from "../../services/local-storage.service";
import { useState, useEffect } from "react";
import { STORAGE } from "../../constants";

type ProfileName = "Supervisor" | "Reportería";

export default function Footer() {
  const [profile, setProfile] = useState("");
  const routes: Record<ProfileName, string> = {
    Supervisor: "/sendCampaigns",
    Reportería: "/reportsCampaigns",
  };

  useEffect(() => {
    const session = LocalStorageService.get(STORAGE.SESSION);
    if (session) {
      const path = routes[session.profile.name as ProfileName];
      if (path) {
        setProfile(path);
      }
    }
  }, []);

  return (
    <>
      <div className={`${styles.footer} footer d-flex flex-lg-column py-3`}>
        <div className="container-fluid d-flex flex-column flex-md-row flex-stack">
          <div className="order-2 order-md-1 d-flex align-items-center">
            <Link to={profile}>
              <span className="text-muted fw-semibold ms-2 text-nowrap">
                Todos los derechos reservados 2024 ©
              </span>
            </Link>
          </div>
          <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
            <li className="menu-item">
              <div className="dropdown">
                <a
                  type="button"
                  className=" menu-link px-5"
                  id="navbarNotificationsDropdown"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  data-bs-dropdown-animation
                >
                  <i className="bi-life-preserver me-1"></i> Necesitas ayuda ?
                </a>
                <div
                  className={`p-0 dropdown-menu dropdown-menu-end dropdown-card navbar-dropdown-menu navbar-dropdown-menu-borderless ${styles.widthDorpdown}`}
                  aria-labelledby="navbarNotificationsDropdown"
                >
                  <div className="card">
                    <div className="card-header card-header-content-between">
                      <h4 className="card-title mb-0">
                        <i className="bi-life-preserver me-2"></i> Ayuda
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="menu-item px-3 my-0">
                        <a
                          className="menu-link px-3 py-2"
                          onClick={() =>
                            window.open(
                              "https://api.whatsapp.com/send?phone=5524156393&text=Hola,%20buen%20d%C3%ADa,%20necesito%20de%20su%20apoyo",
                              "_blank",
                            )
                          }
                        >
                          Hablar con un experto
                          <i className="bi-box-arrow-up-right ms-2"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
