interface ButtonProps {
  children: any;
  type?: "button" | "submit" | "reset" | undefined;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  typeStepperBtn?: string;
  onClick?: any;
  id?: string;
}

function Button({
  children,
  type = "button",
  className = "",
  disabled = false,
  isLoading = false,
  onClick,
  id = "",
  typeStepperBtn,
}: ButtonProps) {
  return (
    <button
      type={type}
      className={className}
      disabled={disabled || isLoading}
      onClick={onClick || (() => {})}
      id={id}
      data-kt-stepper-action={typeStepperBtn}
      data-kt-indicator="on"
    >
      {isLoading ? (
        <>
          Procesando...
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </>
      ) : (
        children
      )}
    </button>
  );
}

export default Button;
