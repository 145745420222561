import { useEffect, useState } from "react";
import { LocalStorageService } from "../services/local-storage.service";
import { STORAGE } from "../constants";

interface useHasAccessProps {
  onlyRoot?: boolean;
  permission?: string;
}

function useHasAccess({ onlyRoot, permission }: useHasAccessProps) {
  const [isAllowed, setIsAllowed] = useState<boolean | null>(null);
  const profile = LocalStorageService.get(STORAGE.SESSION).profile;
  const permissions = profile.permissions || [];

  useEffect(() => {
    if (!profile) {
      setIsAllowed(false);
      return;
    }

    if (onlyRoot) {
      if (profile.name.toLowerCase() === "root") {
        setIsAllowed(true);
      } else {
        setIsAllowed(false);
      }
      return;
    }

    if (permission) {
      const hasPermission = permissions.includes(permission);
      setIsAllowed(hasPermission);
    } else {
      setIsAllowed(false);
    }
  }, [permission, onlyRoot, profile, permissions]);

  return {
    isAllowed,
  };
}

export default useHasAccess;
